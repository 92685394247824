import { Box, Button, styled } from '@mui/material';
import {
  changeCurrentLanguage,
  useCurrentLanguage,
  useLanguages,
} from 'hive-react-utils';
import { memo, useCallback } from 'react';

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  fontSize: '1.5rem',
}));

interface LanguageButtonProps {
  languages: string[];
  current: string;
}

const LanguageButton = memo<LanguageButtonProps>(({ languages, current }) => {
  const changeLanguage = useCallback(() => {
    // !important: languages from useLanguages is string[] contains 'en' and 'fr'
    const language = languages.find((l: string) => l !== current) || current;

    changeCurrentLanguage(language);
  }, [languages, current]);

  return (
    <StyledButton
      size="small"
      color="inherit"
      title={`Toggle language to ${current === 'en' ? 'French' : 'English'}`}
      aria-label={`Toggle language to ${
        current === 'en' ? 'French' : 'English'
      }`}
      role="button"
      onClick={changeLanguage}
    >
      {current}
    </StyledButton>
  );
});
LanguageButton.displayName = 'LanguageButton';

const LanguageToggle = memo(() => {
  const current = useCurrentLanguage();
  const languages = useLanguages();
  return (
    <Box display="flex" flexDirection="row" justifyContent="end">
      <LanguageButton languages={languages} current={current} />
    </Box>
  );
});
LanguageToggle.displayName = 'LanguageToggle';

export default LanguageToggle;
