import { CircularProgress, Grid } from '@mui/material';
import { ConnectionService } from 'hive-client-utils';
import { useHiveConfig } from 'hive-react-utils';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { STORAGE_KEY_OIDC_PROVIDER_ID } from '../../utils/oidc';

export function SignInWithOidc({ redirectTo = '/' }) {
  const location = useLocation();
  const hiveConfig = useHiveConfig();
  const [progress, setProgress] = useState(true);
  const [code, setCode] = useState<string | null>();
  const [providerId, setProviderId] = useState<string | null>();
  const [error, setError] = useState();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setCode(params.get('code'));
    setProviderId(params.get('providerId'));
  }, [location]);

  useEffect(() => {
    if (!code || !hiveConfig) {
      return;
    }

    ConnectionService.signInFromOidcCode(hiveConfig, code)
      .then(() => setProgress(false))
      .then(() => {
        if (providerId) {
          localStorage.setItem(STORAGE_KEY_OIDC_PROVIDER_ID, providerId);
        }
      })
      .catch(setError);
  }, [code, hiveConfig, providerId]);

  if (error) {
    return <>{error}</>;
  }

  if (progress) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size="3rem" />
      </Grid>
    );
  }

  return <Navigate to={redirectTo} />;
}
