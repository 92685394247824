import { ComponentsOverrides } from '@mui/material';
import { COLOURS } from '../constants/constants';

const styleOverrides: ComponentsOverrides['MuiTextField'] = {
  root: {
    '& label': {
      transform: 'scale(1)',
      position: 'relative',
      color: COLOURS.blue.medium,
    },
    '& .MuiInputLabel-asterisk': {
      color: COLOURS.red.dark,
    },
  },
};

export const MuiTextField = {
  styleOverrides,
};
