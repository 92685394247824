import { Button, Container, Typography } from '@mui/material';
import { LoggedConnectionService } from 'hive-analytics-react';
import { useHiveConfig } from 'hive-react-utils';
import { MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InQField } from '../InQField/InQField';

const USE_OIDC = (window as any)?._env_?.REACT_APP_USE_OIDC === 'true';
const HIVE_HOST = (window as any)?._env_?.REACT_APP_HIVE_HOST;
const HIVE_ORG_NAME = (window as any)?._env_?.REACT_APP_HIVE_ORG_NAME;
const HIVE_APP_ID = (window as any)?._env_?.REACT_APP_HIVE_APP_ID;
export const SCREEN_OIDC_DONE = '/oidcSuccess';

const generateRedirectUrl = (path: string) => {
  const { protocol, hostname, port } = window.location;

  const newUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}${
    path || ''
  }`;
  return newUrl;
};

const generateOidcLoginUrl = (): string | null => {
  if (!USE_OIDC) {
    return null;
  }

  const host = HIVE_HOST;
  const successCb = generateRedirectUrl(SCREEN_OIDC_DONE);
  const orgName = HIVE_ORG_NAME;
  const appId = HIVE_APP_ID;
  return `${host}/oidc/login/${orgName}/${appId}?callback=${successCb}`;
};

interface LoginAdmitterProps {
  redirectUrl?: string;
}

function LoginAdmitter({ redirectUrl }: LoginAdmitterProps) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  const hiveConfig = useHiveConfig();
  const oidcLoginUrl = useMemo(() => generateOidcLoginUrl(), []);

  if (oidcLoginUrl) {
    window.location.assign(oidcLoginUrl);
    return <></>;
  }

  async function submit(event: MouseEvent) {
    event.preventDefault();
    try {
      if (!hiveConfig) {
        throw new Error('Hive config not loaded');
      }
      await LoggedConnectionService.signInAsUser(hiveConfig, email, password);
      const nextUrl = redirectUrl || '/';
      navigate(nextUrl);
    } catch (err: any) {
      const message = err.message || err.error_description;
      if (!message) {
        // could not retrieve token, authorization grant invalid (likely wrong details passed from client)
        console.error(err);
      }
      setErrorMessage(message);
      console.error(message);
    }
  }

  return (
    <div className="screen-login-admitter">
      <Container
        component="main"
        maxWidth="xs"
        className="screen-login-admitter"
      >
        <Typography variant="h5" align="center">
          {t('signin.title')}
        </Typography>
        <Typography align="center">{t('signin.message')}</Typography>
        <form>
          <InQField
            label={t('signin.email_label')}
            required
            id="email"
            value={email}
            onInput={setEmail}
            placeholder={t('signin.email_placeholder')}
            autoComplete="email"
            autoFocus
          />
          <InQField
            label={t('signin.password_label')}
            type="password"
            required
            id="password"
            value={password}
            onInput={setPassword}
            placeholder={t('signin.password_placeholder')}
            autoComplete="current-password"
          />
          {errorMessage && (
            <Typography color="error" align="center">
              {errorMessage}
            </Typography>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={submit}
            aria-label={t('signin.button_label')}
            aria-disabled={!email || !password}
            disabled={!email || !password}
            fullWidth
          >
            {t('signin.button_label')}
          </Button>
        </form>
      </Container>
    </div>
  );
}

export default LoginAdmitter;
