import { ComponentsOverrides } from '@mui/material';
import { spacing } from './constants';

const styleOverrides: ComponentsOverrides['MuiPaper'] = {
  root: {
    'header & div.header-tabs': {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, 0%)',
    },
  },

  rounded: {
    '.error-dialog &': {
      padding: `${spacing * 2}px`,
      'border-radius': '8px',
    },
  },
};

export const MuiPaper = {
  styleOverrides,
};
