import { DateRange } from 'react-day-picker';

export enum ReportDurationOptions {
  LAST_WEEK,
  THIS_MONTH,
  LAST_MONTH,
  CUSTOM,
}

export type DatesValid = {
  [key in keyof DateRange]-?: boolean;
};
