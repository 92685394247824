import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Oidc } from './LoginAdmitter/oidc';

const USE_OIDC = (window as any)?._env_?.REACT_APP_USE_OIDC === 'true';

export default function Disconnected(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogin = () => navigate('/login');

  if (USE_OIDC) {
    return <Oidc />;
  }

  return (
    <Stack flexGrow="1" justifyContent="center" alignItems="center">
      <Button variant="contained" onClick={handleLogin}>
        <>{t('signin.please_sign_in')}</>
      </Button>
    </Stack>
  );
}
