import {
  AnalyticsService,
  LoggedConnectionService,
} from 'hive-analytics-react';
import { ConfigService, ConnectionService } from 'hive-client-utils';

export interface LoginServiceOptions {
  appName: string;
  keyToken: string;
  keyTokenExpiration: string;
  keyUsername: string;
}

async function init({
  appName,
  keyToken,
  keyTokenExpiration,
  keyUsername,
}: LoginServiceOptions) {
  const BEE_NAME =
    process.env.REACT_APP_ANALYTICS_BEE_NAME ||
    (window as any)._env_.REACT_APP_ANALYTICS_BEE_NAME;

  const BEE_PASS =
    process.env.REACT_APP_ANALYTICS_BEE_PASS ||
    (window as any)._env_.REACT_APP_ANALYTICS_BEE_PASS;

  await AnalyticsService.init({
    appName,
    environment: process.env.NODE_ENV,
    beeName: BEE_NAME,
    beePass: BEE_PASS,
    eventIds: {
      appStarted: 'App Started',
      signIn: {
        start: 'Sign In',
        end: 'Signed In',
        error: 'Sign In Error',
      },
      click: 'User Click',
      signOut: {
        start: 'Sign Out',
        end: 'Signed Out',
        error: 'Sign Out Error',
      },
      pageChange: {
        start: 'Page Entered',
        end: 'Page Left',
        error: 'Page Error',
      },
      dialog: {
        start: 'Dialog Displayed',
        end: 'Dialog Hidden',
        error: 'Dialog Error',
      },
      action: {
        start: 'Start Action',
        end: 'End Action',
        error: 'Action Error',
      },
    },
  });

  const setBeeFn = (token: ConnectionService.Token, username: string) => {
    localStorage.setItem(keyToken, token.access_token);
    localStorage.setItem(keyTokenExpiration, token.access_token_expiry);
    localStorage.setItem(keyUsername, username);
  };

  const clearBeeFn = () => {
    localStorage.removeItem(keyToken);
    localStorage.removeItem(keyTokenExpiration);
    localStorage.removeItem(keyUsername);
  };

  const loadBeeFn = () => {
    const tokenExpiration = localStorage.getItem(keyTokenExpiration);

    // Check if we have a stored token, if so, we don't need the login screen
    const info: ConnectionService.StorageToken = {
      token: localStorage.getItem(keyToken) || undefined,
      tokenExpiration: tokenExpiration ? parseInt(tokenExpiration) : undefined,
      username: localStorage.getItem(keyUsername) || undefined,
    };

    return info;
  };

  ConnectionService.setupCallbacks({
    setBeeFn,
    clearBeeFn,
    loadBeeFn,
  });

  // Init bee from local storage if possible (when config is available)
  ConfigService.config$.subscribe({
    next: (config: any) => {
      const { pathname } = window.location;
      if (pathname !== '/oidc') {
        LoggedConnectionService.signInFromStorage(config);
      }
    },
    error: (error: any) => console.error('Could not get configuration', error),
  });
}

export const LoginService = {
  init,
};
