export const SCREEN_OIDC_LOGIN = '/login';

export const SCREEN_OIDC_SUCCESS = '/oidcSuccess';

// Just come back to the login page
export const SCREEN_OIDC_ERROR = '/login';

export const STORAGE_KEY_OIDC_PROVIDER_ID = 'oidc-provider-id';

export interface IOidcProvider {
  id: string;
  type: string;
  label: string;
  loginUri: string;
  logoutUri?: string;
}
