export function base64ToString(
  v?: string | null,
  doThrow: boolean = false
): string | undefined {
  try {
    if (v) {
      return Buffer.from(v, 'base64').toString('utf-8');
    }
  } catch (e) {
    if (doThrow) {
      throw e;
    }
    console.error(e);
  }

  // `|| undefined` converts null to undefined
  return v || undefined;
}

export function base64ToJSON(
  v?: string | null,
  doThrow: boolean = false
): Object | undefined {
  try {
    v = base64ToString(v, true);
    if (v) {
      return JSON.parse(v);
    }
  } catch (e) {
    if (doThrow) {
      throw e;
    }
    console.error(e);
  }
}
