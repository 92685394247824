import { GetApp } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { FormEvent, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadPHIWarning } from '../components/DownloadPHIWarning';
import { MainContentBox } from '../components/MainContentBox/MainContentBox';
import { StyledTitle } from '../components/MainContentBox/StyledTitle';
import ReportDateRangePicker from '../components/ReportDateRangePicker/ReportDateRangePicker';
import SelectNetworkClinic from '../components/SelectNetworkClinic/SelectNetworkClinic';
import { REPORT_AGGREGATE, REPORT_FORM } from '../constants/constants';
import { useReportDateRangePickerContext } from '../hooks/useDateRangeContext';
import { useDownloadReportContext } from '../hooks/useDownloadReportContext';
import { ReportDurationOptions } from '../types';
import { dateRangeIsValid } from '../utils';

export const DownloadReportPage = () => {
  const { t } = useTranslation();
  const {
    state: reportFormState,
    handleReportTypeChange,
    handleSubmit,
    noSelectedClinic,
  } = useDownloadReportContext();
  const { state: dateRangeState } = useReportDateRangePickerContext();
  const { areDatesValid, selectedDateRange } = dateRangeState;
  const { loading, formValues } = reportFormState;

  const downloadReportHandler = useCallback(
    (event: FormEvent<HTMLDivElement>) => {
      event.preventDefault();
      handleSubmit(dateRangeState);
    },
    [dateRangeState, handleSubmit]
  );

  return (
    <MainContentBox component="form" onSubmit={downloadReportHandler}>
      <StyledTitle variant="h1">{t('reports.form.title')}</StyledTitle>

      <Typography variant="h2">
        {t('reports.form.downloadUsageReports')}
      </Typography>

      <Stack paddingLeft={2} spacing={2}>
        <FormControl component="fieldset" fullWidth>
          <FormGroup aria-label="report form">
            {REPORT_FORM.map((item) => (
              <Fragment key={item.name}>
                <FormControlLabel
                  aria-label={t(item.label)}
                  label={t(item.label)}
                  labelPlacement="end"
                  control={
                    <Switch
                      checked={formValues[item.name]}
                      name={t(item.name)}
                      inputProps={{
                        role: 'switch',
                      }}
                      onChange={handleReportTypeChange}
                    />
                  }
                />
                <FormHelperText>{t(item.helperText)}</FormHelperText>
              </Fragment>
            ))}
          </FormGroup>
        </FormControl>
      </Stack>

      <Typography variant="h2">
        {t('reports.form.networkClinic.title')}
      </Typography>

      <Stack paddingLeft={2} spacing={2}>
        <FormLabel component="span" className="download-report__label">
          {t('reports.form.networkClinic.subheading')}
        </FormLabel>
        <SelectNetworkClinic />
        <FormControlLabel
          aria-label={t(REPORT_AGGREGATE.label)}
          label={t(REPORT_AGGREGATE.label)}
          labelPlacement="end"
          control={
            <Switch
              checked={formValues.aggregateResult}
              name={t(REPORT_AGGREGATE.name)}
              inputProps={{
                role: 'switch',
              }}
              onChange={handleReportTypeChange}
            />
          }
        />
      </Stack>

      <Typography variant="h2">
        {t('reports.form.customDateRange.title')}
      </Typography>

      <Stack paddingLeft={2} spacing={2}>
        <FormLabel component="span" className="download-report__label">
          {t('reports.form.customDateRange.subheading')}
        </FormLabel>
        <ReportDateRangePicker />
        <DownloadPHIWarning />
        <Box flexGrow={1}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            startIcon={loading ? <CircularProgress size={20} /> : <GetApp />}
            disabled={
              loading ||
              noSelectedClinic ||
              (formValues.reportDurationOption ===
                ReportDurationOptions.CUSTOM &&
                (!dateRangeIsValid(selectedDateRange) ||
                  !areDatesValid.from ||
                  !areDatesValid.to)) ||
              (!formValues.isDenialReport &&
                !formValues.isQueueReport &&
                !formValues.isVisitReport)
            }
          >
            {t('reports.form.submit.button')}
          </Button>
        </Box>
      </Stack>
    </MainContentBox>
  );
};
