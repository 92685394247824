import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useConnection } from 'hive-react-utils';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataContext, { IEmailRecipient } from '../contexts/DataContext';
import { RecipientDialog } from './RecipientDialog';
import { SnackError } from './SnackError';

interface ManageRecipientProps {
  recipient: IEmailRecipient;
  handleDelete: () => void;
}

function ManageRecipient({ recipient, handleDelete }: ManageRecipientProps) {
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState(false);
  return (
    <>
      <Tooltip title={t('user.edit')}>
        <IconButton
          aria-label={t('user.edit')}
          onClick={() => setOpenEdit(true)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('user.delete')}>
        <IconButton aria-label={t('user.delete')} onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <RecipientDialog
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        isEdit={true}
        data={{ ...recipient.properties, recipientId: recipient.id }}
      />
    </>
  );
}

function RecipientRow({
  recipient,
  errorCb,
}: {
  recipient: IEmailRecipient;
  errorCb: (message: string) => void;
}) {
  const { t } = useTranslation();
  const connection = useConnection();

  const handleUpdate = async (config: { usageReport: boolean }) => {
    try {
      if (!connection) {
        throw new Error('no connection');
      }
      await connection.bee.api.invoke(
        'recipient.updateReportRecipient',
        recipient.id,
        recipient.properties.name,
        recipient.properties.email,
        config
      );
    } catch (e) {
      console.error('error updating recipient: ', e);
      errorCb(t('recipient.error.update'));
    }
  };

  const handleDelete = async () => {
    try {
      if (!connection) {
        throw new Error('no connection');
      }
      await connection.bee.api.invoke(
        'recipient.deleteReportRecipient',
        recipient.id
      );
    } catch (e) {
      console.error('error removing recipient: ', e);
      errorCb(t('recipient.error.remove'));
    }
  };

  return (
    <TableRow key={recipient.id}>
      <TableCell>{recipient.properties.name}</TableCell>
      <TableCell>{recipient.properties.email}</TableCell>
      <TableCell>
        <Switch
          checked={recipient.properties.reportConfig.usageReport}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleUpdate({
              ...recipient.properties.reportConfig,
              usageReport: e.target.checked,
            })
          }
        />
      </TableCell>
      <TableCell align="right">
        <ManageRecipient recipient={recipient} handleDelete={handleDelete} />
      </TableCell>
    </TableRow>
  );
}

export function EmailReportTable() {
  const { t } = useTranslation();
  const { emailRecipients } = useContext(DataContext);
  const [snackError, setSnackError] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  const handleSnackError = (message: string) => {
    setSnackMessage(message);
    setSnackError(true);
  };

  const handleSnackClose = () => {
    setSnackError(false);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label={t('email_reports.table.aria')}>
        <TableHead>
          <TableRow>
            <TableCell>{t('email_reports.table.name')}</TableCell>
            <TableCell>{t('email_reports.table.email')}</TableCell>
            <TableCell>
              {t('email_reports.table.network_usage_report')}
            </TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(emailRecipients, (recipient) => {
            return (
              <RecipientRow
                recipient={recipient}
                errorCb={handleSnackError}
                key={recipient.id}
              />
            );
          })}
        </TableBody>
      </Table>
      <SnackError
        open={snackError}
        handleClose={handleSnackClose}
        message={snackMessage}
      />
    </TableContainer>
  );
}
