import constants from './constants';
import { createTheme, ThemeOptions } from '@mui/material';
import { MuiCard, MuiCardContent } from './card';
import { MuiContainer } from './container';
import { MuiDialogActions, MuiDialogContent, MuiDialogTitle } from './dialog';
import { MuiInputBase } from './input';
import { paletteOverride } from './palette';
import { MuiPaper } from './paper';
import { MuiTab, MuiTabs } from './tab';
import {
  MuiTable,
  MuiTableCell,
  MuiTableContainer,
  MuiTableHead,
  MuiTableRow,
} from './table';
import { MuiTextField } from './textfield';
import { MuiToolbar } from './toolbar';
import { MuiTypography } from './typography';

const componentOverrides: ThemeOptions = {
  components: {
    MuiCard,
    MuiCardContent,
    MuiContainer,
    MuiDialogActions,
    MuiDialogContent,
    MuiDialogTitle,
    MuiInputBase,
    MuiPaper,
    MuiTab,
    MuiTabs,
    MuiTable,
    MuiTableCell,
    MuiTableContainer,
    MuiTableHead,
    MuiTableRow,
    MuiTextField,
    MuiToolbar,
    MuiTypography,
  },
};

export const theme = createTheme({
  ...componentOverrides,
  ...paletteOverride,
  ...constants,
});
