import { SvgIcon, SvgIconProps } from '@mui/material';
export function ResourcesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M2.85742 2.85717C2.85742 2.69937 2.98534 2.57145 3.14314 2.57145H4.28599C4.44379 2.57145 4.57171 2.69937 4.57171 2.85717C4.57171 3.01496 4.44379 3.14288 4.28599 3.14288H3.42885V23.2857H18.0003V22.4286C18.0003 22.2708 18.1282 22.1429 18.286 22.1429C18.4438 22.1429 18.5717 22.2708 18.5717 22.4286V23.5715C18.5717 23.7293 18.4438 23.8572 18.286 23.8572H3.14314C2.98534 23.8572 2.85742 23.7293 2.85742 23.5715V2.85717Z" />
      <path d="M5.42885 0.428598C5.42885 0.270802 5.55677 0.142883 5.71456 0.142883H17.286C17.3618 0.142883 17.4344 0.172985 17.488 0.226567L20.9166 3.65514C20.9702 3.70872 21.0003 3.78139 21.0003 3.85717V21C21.0003 21.1578 20.8724 21.2857 20.7146 21.2857H5.71456C5.55677 21.2857 5.42885 21.1578 5.42885 21V0.428598ZM6.00028 0.714312V20.7143H20.4288V4.14288H17.286C17.1282 4.14288 17.0003 4.01496 17.0003 3.85717V0.714312H6.00028ZM17.5717 1.11837L20.0248 3.57145H17.5717V1.11837Z" />
      <path d="M7.57171 3.71431C7.57171 3.55652 7.69963 3.4286 7.85742 3.4286H13.1431C13.3009 3.4286 13.4288 3.55652 13.4288 3.71431C13.4288 3.87211 13.3009 4.00003 13.1431 4.00003H7.85742C7.69963 4.00003 7.57171 3.87211 7.57171 3.71431Z" />
      <path d="M16.5717 6.57146C16.7295 6.57146 16.8574 6.69937 16.8574 6.85717V11.7143C16.8574 11.8721 16.7295 12 16.5717 12C16.4139 12 16.286 11.8721 16.286 11.7143V6.85717C16.286 6.69937 16.4139 6.57146 16.5717 6.57146ZM18.286 7.71431C18.4438 7.71431 18.5717 7.84223 18.5717 8.00003V11.7143C18.5717 11.8721 18.4438 12 18.286 12C18.1282 12 18.0003 11.8721 18.0003 11.7143V8.00003C18.0003 7.84223 18.1282 7.71431 18.286 7.71431ZM14.8574 8.85717C15.0152 8.85717 15.1431 8.98509 15.1431 9.14288V11.7143C15.1431 11.8721 15.0152 12 14.8574 12C14.6996 12 14.5717 11.8721 14.5717 11.7143V9.14288C14.5717 8.98509 14.6996 8.85717 14.8574 8.85717Z" />
      <path d="M7.57171 13.4286C7.57171 13.2708 7.69963 13.1429 7.85742 13.1429H18.7146C18.8724 13.1429 19.0003 13.2708 19.0003 13.4286C19.0003 13.5864 18.8724 13.7143 18.7146 13.7143H7.85742C7.69963 13.7143 7.57171 13.5864 7.57171 13.4286ZM7.57171 15.1429C7.57171 14.9851 7.69963 14.8572 7.85742 14.8572H11.7146C11.8724 14.8572 12.0003 14.9851 12.0003 15.1429V17C12.0003 17.1578 11.8724 17.2857 11.7146 17.2857H7.85742C7.69963 17.2857 7.57171 17.1578 7.57171 17V15.1429ZM8.14314 15.4286V16.7143H11.4288V15.4286H8.14314ZM12.8574 15.1429C12.8574 14.9851 12.9853 14.8572 13.1431 14.8572H18.7146C18.8724 14.8572 19.0003 14.9851 19.0003 15.1429C19.0003 15.3007 18.8724 15.4286 18.7146 15.4286H13.1431C12.9853 15.4286 12.8574 15.3007 12.8574 15.1429ZM12.8574 16.8572C12.8574 16.6994 12.9853 16.5715 13.1431 16.5715H18.7146C18.8724 16.5715 19.0003 16.6994 19.0003 16.8572C19.0003 17.015 18.8724 17.1429 18.7146 17.1429H13.1431C12.9853 17.1429 12.8574 17.015 12.8574 16.8572Z" />
      <path d="M7.42885 18.5715C7.42885 18.4137 7.55677 18.2857 7.71456 18.2857H11.8574C12.0152 18.2857 12.1431 18.4137 12.1431 18.5715C12.1431 18.7293 12.0152 18.8572 11.8574 18.8572H7.71456C7.55677 18.8572 7.42885 18.7293 7.42885 18.5715Z" />
      <path d="M10.0003 6.87403C9.62111 6.91895 9.25645 7.05284 8.93675 7.26646C8.53737 7.53331 8.2261 7.9126 8.04229 8.35637C7.85847 8.80013 7.81038 9.28843 7.90409 9.75953C7.99779 10.2306 8.22909 10.6634 8.56873 11.003C8.90838 11.3426 9.34111 11.5739 9.8122 11.6676C10.2833 11.7614 10.7716 11.7133 11.2154 11.5294C11.6591 11.3456 12.0384 11.0344 12.3053 10.635C12.5189 10.3153 12.6528 9.95062 12.6977 9.57146H10.286C10.2102 9.57146 10.1375 9.54135 10.084 9.48777C10.0304 9.43419 10.0003 9.36152 10.0003 9.28574V6.87403ZM8.61928 6.79133C9.11263 6.46169 9.69265 6.28574 10.286 6.28574C10.3618 6.28574 10.4344 6.31584 10.488 6.36942C10.5416 6.42301 10.5717 6.49568 10.5717 6.57146V9.00003H13.0003C13.1581 9.00003 13.286 9.12795 13.286 9.28574C13.286 9.87908 13.11 10.4591 12.7804 10.9525C12.4508 11.4458 11.9822 11.8303 11.434 12.0574C10.8859 12.2844 10.2827 12.3439 9.70072 12.2281C9.11878 12.1123 8.58423 11.8266 8.16467 11.4071C7.74512 10.9875 7.45939 10.453 7.34364 9.87101C7.22788 9.28907 7.28729 8.68587 7.51435 8.13769C7.74142 7.58951 8.12594 7.12098 8.61928 6.79133Z" />
      <path d="M10.9411 5.51228C10.9947 5.4587 11.0674 5.4286 11.1431 5.4286C11.5371 5.4286 11.9272 5.5062 12.2912 5.65696C12.6552 5.80772 12.9859 6.0287 13.2645 6.30728C13.543 6.58585 13.764 6.91657 13.9148 7.28055C14.0655 7.64452 14.1431 8.03463 14.1431 8.4286C14.1431 8.50437 14.113 8.57705 14.0595 8.63063C14.0059 8.68421 13.9332 8.71431 13.8574 8.71431L11.1431 8.71431C10.9853 8.71431 10.8574 8.58639 10.8574 8.4286V5.71431C10.8574 5.63854 10.8875 5.56586 10.9411 5.51228ZM11.4288 6.01689V8.14288L13.5548 8.14288C13.5287 7.92209 13.4723 7.70547 13.3868 7.49922C13.2648 7.20458 13.0859 6.93685 12.8604 6.71134C12.6349 6.48583 12.3672 6.30694 12.0725 6.18489C11.8663 6.09946 11.6496 6.04305 11.4288 6.01689Z" />
    </SvgIcon>
  );
}
