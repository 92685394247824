import { ComponentsOverrides } from '@mui/material';
import { COLOURS } from '../constants/constants';

const headStyleOverrides: ComponentsOverrides['MuiTableHead'] = {
  root: {
    backgroundColor: COLOURS.grey.cadet.medium,
    '& .MuiTableRow-root > .MuiTableCell-root': {
      color: COLOURS.white,
    },
  },
};

export const MuiTableHead = {
  styleOverrides: headStyleOverrides,
};

const rowStyleOverrides: ComponentsOverrides['MuiTableRow'] = {
  root: {},
};

export const MuiTableRow = {
  styleOverrides: rowStyleOverrides,
};

const cellStyleOverrides: ComponentsOverrides['MuiTableCell'] = {
  root: {},
};

export const MuiTableCell = {
  styleOverrides: cellStyleOverrides,
};

const styleOverrides: ComponentsOverrides['MuiTable'] = {
  root: {},
};

export const MuiTable = {
  styleOverrides,
};

const containerStyleOverrides: ComponentsOverrides['MuiTableContainer'] = {
  root: {
    borderRadius: '8px',
    width: '100%',
  },
};

export const MuiTableContainer = {
  styleOverrides: containerStyleOverrides,
};
