import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLOURS } from '../../constants/constants';

import DataContext, { IUser } from '../../contexts/DataContext';
import { UserDialog } from '../UserDialog';
import './UserTable.scss';

interface ManageUserProps {
  user: IUser;
  deletable: boolean;
  handleDelete: () => void;
}

function ManageUser({ user, deletable, handleDelete }: ManageUserProps) {
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState(false);
  return (
    <>
      <Tooltip title={t('user.edit')}>
        <IconButton
          aria-label={t('user.edit')}
          onClick={() => setOpenEdit(true)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('user.delete')} className={deletable ? '' : 'hidden'}>
        <IconButton aria-label={t('user.delete')} onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <UserDialog
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        isEdit={true}
        data={{
          userId: user.properties.userId,
          email: user.properties.email,
          phone: user.properties.phone,
          displayName: user.properties.displayName,
          clinicName: user.properties.clinicName,
        }}
      />
    </>
  );
}

interface UserTableProps {
  onDelete?: (userId: string, name: string) => void;
}

export function UserTable({ onDelete }: UserTableProps) {
  const { t } = useTranslation();
  const { users, currentUser } = useContext(DataContext);

  const handleDelete = (uid: string, name: string) => {
    if (onDelete) {
      onDelete(uid, name);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label={t('user.management.table.aria')}>
        <TableHead>
          <TableRow>
            <TableCell>{t('user.management.table.name')}</TableCell>
            <TableCell>{t('user.management.table.affiliation')}</TableCell>
            <TableCell>{t('user.management.table.email')}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(users, (user) => {
            return (
              <TableRow key={user.properties.userId}>
                <TableCell>{user.properties.displayName}</TableCell>
                <TableCell>{user.properties.clinicName}</TableCell>
                <TableCell
                  sx={{ color: COLOURS.grey.dark, fontStyle: 'italic' }}
                >
                  {user.properties.email}
                </TableCell>
                <TableCell align="right">
                  <ManageUser
                    user={user}
                    deletable={
                      user.properties.userId !== currentUser?.properties.userId
                    }
                    handleDelete={() =>
                      handleDelete(
                        user.properties.userId,
                        user.properties.displayName
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
