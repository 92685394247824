import { ThemeOptions } from '@mui/material';
import { COLOURS } from '../constants/constants';

export const paletteOverride: ThemeOptions = {
  palette: {
    primary: {
      main: COLOURS.blue.medium,
      light: COLOURS.blue.light,
      dark: COLOURS.blue.dark,
    },
    secondary: {
      main: COLOURS.white,
    },
  },
};
