import { List, styled } from '@mui/material';
import { COLOURS } from '../../constants/constants';

const StyledList = styled(List)(({ theme }) => ({
  width: '100%',
  border: `1px solid ${COLOURS.grey.medium}`,
  padding: theme.spacing(0),
}));

export default StyledList;
