import { Box, Link as MuiLink, Paper, Stack } from '@mui/material';
import { useCurrentLanguage } from 'hive-react-utils';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoEn } from '../assets/EMIDS_HealthDefinesUs_EN.svg';
import { ReactComponent as LogoFr } from '../assets/EMIDS_HealthDefinesUs_FR.svg';

export default function Footer(): JSX.Element {
  const { t } = useTranslation();
  const language = useCurrentLanguage();

  const logo = language === 'fr' ? <LogoFr /> : <LogoEn />;
  const contactEmail = t('footer.contactEmail');

  return (
    <Box justifySelf="flex-end">
      <footer>
        <Paper>
          <Stack
            direction="row"
            spacing={1}
            padding={1}
            paddingLeft={2}
            paddingRight={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <MuiLink
              href="https://www.emids.com"
              target="_blank"
              display="contents"
            >
              {logo}
            </MuiLink>

            <Stack
              direction="row"
              justifyContent="center"
              spacing={2}
              flexGrow={1}
            >
              <MuiLink
                href="https://www.emids.com/privacy-policy/"
                variant="body2"
                target="_blank"
              >
                <>{t('footer.privacyPolicy')}</>
              </MuiLink>
            </Stack>

            <MuiLink variant="body2" target="_blank" href={contactEmail}>
              <>{t('footer.contactUs')}</>
            </MuiLink>
          </Stack>
        </Paper>
      </footer>
    </Box>
  );
}
