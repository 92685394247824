import { Button, Stack, Typography } from '@mui/material';
import { useConnection } from 'hive-react-utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../components/ConfirmDialog';
import { MainContentBox } from '../components/MainContentBox/MainContentBox';
import { StyledTitle } from '../components/MainContentBox/StyledTitle';
import { SnackError } from '../components/SnackError';
import { UserDialog, initUserData } from '../components/UserDialog';
import { UserTable } from '../components/UserTable/UserTable';

export function UserManagementPage() {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deletedUserId, setDeletedUserId] = useState<null | string>(null);
  const [deletedUserName, setDeletedUserName] = useState<string>('');
  const [error, setError] = useState<null | string>(null);
  const connection = useConnection();

  const handleSnackClose = () => {
    setDeletedUserId(null);
    setDeletedUserName('');
    setError(null);
  };

  const handleDelete = (userId: string, displayName: string) => {
    setDeletedUserId(userId);
    setOpenConfirmDialog(true);
    setDeletedUserName(displayName);
  };

  const onConfirm = async () => {
    if (!connection || !deletedUserId) {
      return;
    }

    try {
      await connection.bee.api.invoke(
        'network.deleteNetworkUser',
        deletedUserId
      );
    } catch (err) {
      setError(`Error deleting user: ${deletedUserId}`);
    } finally {
      setDeletedUserId(null);
      setDeletedUserName('');
      setOpenConfirmDialog(false);
    }
  };

  const onCancel = () => {
    setDeletedUserId(null);
    setDeletedUserName('');
    setOpenConfirmDialog(false);
  };

  return (
    <MainContentBox>
      <StyledTitle>{t('user.management.title')}</StyledTitle>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={12}
      >
        <Typography>{t('user.management.description')}</Typography>
        <Button variant="outlined" onClick={() => setOpenDialog(true)}>
          {t('user.add_title')}
        </Button>
      </Stack>
      <UserTable onDelete={handleDelete} />
      <UserDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        isEdit={false}
        data={initUserData}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        onConfirm={onConfirm}
        onCancel={onCancel}
        userName={deletedUserName}
      />
      <SnackError
        open={!!error}
        handleClose={handleSnackClose}
        message={t('user.management.error')}
      />
    </MainContentBox>
  );
}
