import { useMemo } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import _fp from 'lodash/fp';

export type ErrorDialogMessage = string | JSX.Element;

export interface ErrorDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  message: ErrorDialogMessage | ErrorDialogMessage[];
  closeButtonLabel: string;
  className?: string;
}

export function ErrorDialog({
  open,
  onClose,
  title,
  message,
  closeButtonLabel,
  className = '',
}: ErrorDialogProps): JSX.Element {
  const content = useMemo(
    () =>
      _fp.flow([
        _fp.castArray,
        _fp.compact,
        (_fp as any)
          .convert({ cap: false })
          .map((m: ErrorDialogMessage, index: number) => {
            if (typeof m === 'string') {
              return <Typography key={index}>{m}</Typography>;
            }

            return m as JSX.Element;
          }),
      ])(message),
    [message]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={`error-dialog ${className}`}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>{content}</DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {closeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
