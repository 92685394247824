import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import './services/LocalizationService';
import { LoginService } from './services/LoginService';

const KEY_TOKEN = 'hive-network-token';
const KEY_TOKEN_EXPIRATION = 'hive-network-token-expiration';
const KEY_USERNAME = 'hive-network-username';

LoginService.init({
  appName: 'raam-network-app',
  keyToken: KEY_TOKEN,
  keyTokenExpiration: KEY_TOKEN_EXPIRATION,
  keyUsername: KEY_USERNAME,
}).then(() => {
  const container = document.getElementById('root') as Element;
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});
