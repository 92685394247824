import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import { SyntheticEvent } from 'react';

export function SnackError({
  open,
  handleClose,
  message,
}: {
  open: boolean;
  handleClose: (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => void;
  message: string;
}) {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={30000}
      onClose={handleClose}
    >
      <Alert severity="error" variant="filled" onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
}
