import {
  AppBar,
  Box,
  Button,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ConnectionService } from 'hive-client-utils';
import { useConnectionState } from 'hive-react-utils';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import ClinicLogo from '../assets/1FD_logo.png';
import LogoutLogo from '../assets/logout_white.svg';
import DataContext from '../contexts/DataContext';
import { theme } from '../theme';
import LanguageToggle from './LanguageToggle';
import { NavigationTabs } from './NavigationPanel/NavigationPanel';
import { useOidcProviderFromStorage } from '../hooks/useOidcProviders';

const FlexContainer = styled(Box)(() => ({
  display: 'flex',
}));

const FlexAlignCenterContainer = styled(FlexContainer)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

interface StyledAppBarProps {
  trigger: 'true' | 'false';
}
const StyledAppBar = styled(AppBar)<StyledAppBarProps>(({ trigger }) => ({
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: trigger === 'true' ? theme.shadows[4] : 'none',
  transition: theme.transitions.create(['box-shadow'], {
    duration: theme.transitions.duration.standard,
    easing: theme.transitions.easing.easeInOut,
  }),
}));

function LogoutButton() {
  const { t } = useTranslation();
  const oidcProvider = useOidcProviderFromStorage();

  const handleLogout = useCallback(() => {
    ConnectionService.releaseBee().then(() => {
      if (!oidcProvider?.logoutUri) {
        return;
      }

      window.location.assign(oidcProvider.logoutUri);
    });
  }, [oidcProvider?.logoutUri]);

  return (
    <Tooltip title={t('header.logout')}>
      <Button variant="contained" onClick={handleLogout}>
        <img src={LogoutLogo} alt={t('header.logout')} />
      </Button>
    </Tooltip>
  );
}

function RightHeader(): JSX.Element {
  const { currentUser } = useContext(DataContext);
  const connectionState = useConnectionState() || 'default';
  const connected = useMemo(() => {
    return _.includes(
      [
        ConnectionService.ConnectionState.CONNECTED,
        ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN,
      ],
      connectionState
    );
  }, [connectionState]);

  return (
    <Stack direction="row" spacing={1} alignItems="center" flexGrow={1}>
      {currentUser && (
        <Typography color="primary">
          {currentUser?.properties.displayName}
        </Typography>
      )}
      <LanguageToggle />
      {connected && <LogoutButton />}
    </Stack>
  );
}

export default function Header(): JSX.Element {
  const { t } = useTranslation();
  const connectionState = useConnectionState() || 'default';
  const ref = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<null | number>(null);
  const small = useMediaQuery(theme.breakpoints.down('xl'));

  const connected = useMemo(() => {
    return _.includes(
      [
        ConnectionService.ConnectionState.CONNECTED,
        ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN,
      ],
      connectionState
    );
  }, [connectionState]);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    setHeaderHeight(ref.current.clientHeight);
  }, []);

  return (
    <Box
      {...(headerHeight !== null && {
        marginBottom: headerHeight + 'px',
      })}
    >
      <StyledAppBar position="fixed" ref={ref} trigger="true">
        <Toolbar>
          <FlexContainer>
            <nav>
              <NavLink to="/" role="navigation" aria-label={t('homepage')}>
                <img src={ClinicLogo} alt="logo" height="60px" width="auto" />
              </NavLink>
            </nav>
          </FlexContainer>
          {connected && small && (
            <FlexContainer>
              <NavigationTabs orientation="horizontal" />
            </FlexContainer>
          )}
          <FlexAlignCenterContainer>
            <RightHeader />
          </FlexAlignCenterContainer>
        </Toolbar>
      </StyledAppBar>
    </Box>
  );
}
