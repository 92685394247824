import { Container, Stack, styled, useMediaQuery } from '@mui/material';
import { ReactNode, memo } from 'react';
import { NavigationPanel } from '../components/NavigationPanel/NavigationPanel';
import { ReportDateRangePickerContextProvider } from '../hooks/useDateRangeContext';
import { DownloadReportFormContextProvider } from '../hooks/useDownloadReportContext';
import { theme } from '../theme';

export interface SettingsLayoutProps {
  buttons?: JSX.Element;
  center?: JSX.Element;
  children?: JSX.Element | ReactNode;
}

const ContentContainer = styled(Container)(() => ({
  height: '100%',
  backgroundColor: 'white',
}));

const ContentLayout = memo<SettingsLayoutProps>(({ children }) => {
  const large = useMediaQuery(theme.breakpoints.up('xl'));
  return (
    <Stack direction="row" height="100%">
      {large && <NavigationPanel />}
      <ContentContainer>{children}</ContentContainer>
    </Stack>
  );
});

ContentLayout.displayName = 'ContentLayout';

const Content = memo(({ mainContent }: { mainContent: JSX.Element }) => {
  return (
    <ContentLayout>
      <DownloadReportFormContextProvider>
        <ReportDateRangePickerContextProvider>
          {mainContent}
        </ReportDateRangePickerContextProvider>
      </DownloadReportFormContextProvider>
    </ContentLayout>
  );
});

Content.displayName = 'Content';

export default Content;
