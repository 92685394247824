import { Box, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { enCA, frCA } from 'date-fns/locale';
import { useCurrentLanguage } from 'hive-react-utils';
import moment from 'moment';
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DateRange,
  DayPicker,
  SelectRangeEventHandler,
} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useTranslation } from 'react-i18next';
import { COLOURS } from '../../constants/constants';
import { useReportDateRangePickerContext } from '../../hooks/useDateRangeContext';
import {
  dateInTheFuture,
  dateRangeIsInOrder,
  dateRangeIsValid,
  dateRangeTooLong,
} from '../../utils';
import './ReportDateRangePicker.scss';

/** Maximum number of days between the start and end date of a valid DateRange */
const maxDateRangeLength = 365;

interface DayPickerGroupProps {
  groupLabel: string;
  dateRangeKey: keyof DateRange;
  locale: Locale;
  footer?: ReactNode;
}

function DayPickerGroup({
  groupLabel,
  dateRangeKey,
  locale,
  footer,
}: DayPickerGroupProps) {
  const { state, handleDatePickerChange, selectDateRangeHandler } =
    useReportDateRangePickerContext();
  const { selectedDateRange, areDatesValid } = state;

  const day = selectedDateRange?.[dateRangeKey];
  const dateFormat = 'YYYY-MM-DD';
  const formattedDate = moment(day ?? '').format(dateFormat);
  const [defaultDate, setDefaultDate] = useState<string | undefined>(
    formattedDate
  );
  const [forceCalendarSync, setForceCalendarSync] = useState(false);

  useEffect(() => {
    if (forceCalendarSync) {
      setForceCalendarSync(false);
    }
    setDefaultDate(formattedDate);
    // Value set to undefined in a timeout because the temporary change doesn't register otherwise.
    const timeoutID = setTimeout(() => setDefaultDate(undefined));

    return () => clearTimeout(timeoutID);
  }, [formattedDate, forceCalendarSync]);

  const { t } = useTranslation();

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      (dateRangeKey === 'from'
        ? selectedDateRange?.to &&
          moment(selectedDateRange.to).isBefore(event.target.value)
        : selectedDateRange?.from &&
          moment(selectedDateRange.from).isAfter(event.target.value)) ||
      moment(event.target.value).isAfter(moment())
    ) {
      return setForceCalendarSync(true);
    }
    handleDatePickerChange(event, dateRangeKey);
  };

  const errors: string[] = [];
  const invalidDateRange =
    !dateRangeIsValid(selectedDateRange) || !areDatesValid[dateRangeKey];

  if (invalidDateRange) {
    if (!areDatesValid[dateRangeKey]) {
      errors.push(t('reports.form.customDateRange.error.invalidDate'));
    }
    if (
      !selectedDateRange ||
      !(selectedDateRange.from || selectedDateRange.to)
    ) {
      errors.push(t('reports.form.customDateRange.error.noDateRange'));
    } else if (!selectedDateRange.from) {
      errors.push(t('reports.form.customDateRange.error.noStartDate'));
    } else {
      if (dateInTheFuture(selectedDateRange?.[dateRangeKey])) {
        errors.push(t('reports.form.customDateRange.error.dateInFuture'));
      }
      if (!dateRangeIsInOrder(selectedDateRange)) {
        errors.push(t('reports.form.customDateRange.error.rangeNotSane'));
      } else if (dateRangeTooLong(selectedDateRange)) {
        errors.push(
          t('reports.form.customDateRange.error.rangeTooLong', {
            max: maxDateRangeLength.toString(),
          })
        );
      }
    }
  }

  // What is displayed to the user
  const pickerSelectedRange = useMemo(() => {
    if (
      selectedDateRange?.from &&
      !selectedDateRange?.to &&
      dateRangeKey === 'to'
    ) {
      return undefined;
    } else if (!selectedDateRange?.from && selectedDateRange?.to) {
      if (dateRangeKey === 'from') {
        return undefined;
      } else {
        return { from: selectedDateRange.to };
      }
    }
    return selectedDateRange;
  }, [selectedDateRange, dateRangeKey]);

  const errorMessage = errors.join(', ');

  const onDayPickerSelect = useCallback<SelectRangeEventHandler>(
    (_range, selectedDay) => {
      if (!selectedDateRange) {
        return selectDateRangeHandler({
          from: undefined,
          [dateRangeKey]: selectedDay,
        });
      }
      if (
        !selectedDateRange[dateRangeKey] ||
        Math.abs(
          moment(selectedDateRange[dateRangeKey]).diff(selectedDay, 'days')
        ) >= 1
      ) {
        return selectDateRangeHandler({
          ...selectedDateRange,
          [dateRangeKey]: selectedDay,
        });
      } else {
        return selectDateRangeHandler({
          ...selectedDateRange,
          [dateRangeKey]: undefined,
        });
      }
    },
    [selectedDateRange, dateRangeKey, selectDateRangeHandler]
  );

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      borderRadius="7px"
      border="1px solid black"
    >
      <Typography
        variant="subtitle1"
        sx={{
          display: 'block',
          position: 'absolute',
          top: '0',
          left: '0',
          padding: '0',
          margin: '0',
          zIndex: 10,
          transform: 'translate(24px, -1.1em)',
          overflow: 'hidden',
          backgroundColor: COLOURS.white,
          fontSize: '0.75rem',
          fontStyle: 'italic',
          color: COLOURS.blue.medium,
        }}
      >
        {groupLabel}
      </Typography>

      <Tooltip
        title={errorMessage}
        open={invalidDateRange}
        arrow
        placement="top"
      >
        <TextField
          id={`${dateRangeKey}Date`}
          type="date"
          value={defaultDate}
          InputProps={{
            sx: {
              color: 'black',
              border: 'none',
            },
          }}
          inputProps={{
            max: moment().format(dateFormat),
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            },
            ...(dateRangeKey === 'from'
              ? selectedDateRange?.to
                ? { max: moment(selectedDateRange.to).format(dateFormat) }
                : undefined
              : selectedDateRange?.from
              ? { min: moment(selectedDateRange.from).format(dateFormat) }
              : undefined),
          }}
          sx={{
            width: '100%',
            padding: '0 1em',
            marginBottom: '0 !important',
            marginTop: '0.5em',
          }}
          InputLabelProps={{
            style: {
              border: 'none !important',
            },
          }}
          onChange={handleTextFieldChange}
          error={invalidDateRange}
          onClick={(event) => event.preventDefault()}
        />
      </Tooltip>

      <DayPicker
        style={{
          marginTop: '0',
        }}
        toDate={new Date()}
        defaultMonth={day ?? new Date()}
        mode="range"
        min={0}
        max={maxDateRangeLength}
        selected={pickerSelectedRange}
        onSelect={onDayPickerSelect}
        footer={footer}
        locale={locale}
        showOutsideDays
        {...(dateRangeKey === 'from'
          ? selectedDateRange?.to
            ? { toDate: selectedDateRange.to }
            : undefined
          : selectedDateRange?.from
          ? { fromDate: selectedDateRange.from }
          : undefined)}
      />
    </Box>
  );
}

export interface ReportDateRangePickerProps {
  footer?: ReactNode;
}

export default function ReportDateRangePicker({
  footer,
}: ReportDateRangePickerProps) {
  const { t } = useTranslation();
  const current = useCurrentLanguage();
  const locale = current !== 'en' ? frCA : enCA;

  return (
    <Box
      sx={{
        maxWidth: '700px',
        justifyContent: 'center',
      }}
    >
      <Grid container spacing={2}>
        <Grid container item xs={12} gap="1em">
          <DayPickerGroup
            groupLabel={t('reports.form.customDateRange.startDate')}
            dateRangeKey="from"
            locale={locale}
            footer={footer}
          />

          <DayPickerGroup
            groupLabel={t('reports.form.customDateRange.endDate')}
            dateRangeKey="to"
            locale={locale}
            footer={footer}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
