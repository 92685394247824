import { TextField, TextFieldProps } from '@mui/material';
import _ from 'lodash';
import React from 'react';

type InQFieldProps = Omit<TextFieldProps, 'onChange' | 'onInput'> & {
  onChange?: (value: string) => void;
  onInput?: (value: string) => void;
  onEnter?: () => void;
  hint?: React.ReactNode;
  labelTwo?: string;
};

const InQFieldDefaultInput = (props: InQFieldProps): JSX.Element => {
  props = _.omit(props, 'label');
  const { children, onChange, onInput, onEnter, ...moreProps } = props;

  if (!_.isNil(children) && !props.select) {
    if (
      props.required ||
      props.placeholder ||
      props.value ||
      props.InputProps ||
      props.error ||
      props.id ||
      onChange ||
      onInput ||
      onEnter
    ) {
      throw new Error(
        `InQFieldDefaultInput (${props.label}): You have provided a child element so this component is working in custom field mode.  As a result you must remove all data bindings from InQFieldDefaultInput except for label`
      );
    }
    return <>{children}</>;
  } else {
    if (
      props.value === undefined &&
      onChange === undefined &&
      onInput === undefined
    ) {
      throw new Error(
        `InQFieldDefaultInput (${props.label}): You have not provided a child element so this component is working in TextField/Readonly mode.  As a result you must provide either a value or an onChange handler`
      );
    }

    if (onChange || onInput) {
      const wrappedOnChange = onChange
        ? (e: any) => onChange(e?.target?.value?.toString())
        : () => {};
      const wrappedOnInput = onInput
        ? (e: any) => onInput(e?.target?.value?.toString())
        : () => {};
      const wrappedOnEnter = onEnter
        ? (e: any) => {
            if (e.keyCode === 13) {
              onEnter();
            }
          }
        : () => {};

      return (
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          onChange={wrappedOnChange}
          onInput={wrappedOnInput}
          onKeyUp={wrappedOnEnter}
          {...moreProps}
        >
          {!_.isNil(children) ? children : ''}
        </TextField>
      );
    } else {
      return <>{props.value}</>;
    }
  }
};

export const InQField = (props: InQFieldProps) => {
  const { className, id, label, labelTwo, hint, ...moreProps } = props;

  return (
    <div className={`inq-field ${className}`} id={id}>
      <div className="field-label">{label}</div>
      {labelTwo && <div className="field-label">{labelTwo}</div>}
      <div className="field-value">
        <InQFieldDefaultInput {...moreProps} />
      </div>
      {hint ? <div className="field-hint">{hint}</div> : <></>}
    </div>
  );
};

export default InQField;
