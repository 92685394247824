import {
  CssBaseline,
  Stack,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { useTitleEffect } from 'hive-react-utils';
import { useEffect } from 'react';
import { DataContextProvider } from './contexts/DataContext';
import Main from './Main';
import { theme } from './theme';
import buildNumber from './build-number';

export default function App() {
  useTitleEffect('app.title');
  useEffect(() => console.log(`Network App version: ${buildNumber}`), []);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <Stack height="100%">
          <DataContextProvider>
            <Main />
          </DataContextProvider>
        </Stack>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}
