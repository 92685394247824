import { ComponentsOverrides } from '@mui/material';
import { spacing } from './constants';

const styleOverrides: ComponentsOverrides['MuiToolbar'] = {
  root: {
    paddingTop: spacing,
    paddingBottom: spacing,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
};

export const MuiToolbar = {
  styleOverrides,
};
