import { PrivacyPolicy_fr as privacyPolicyText } from './PrivacyPolicy';
import { TermsOfService_fr as termsOfServiceText } from './TermsOfService';

const APP_TITLE = 'Configuration des cliniques RAAM';

export const fr = {
  app: {
    title: APP_TITLE,
  },
  header: {
    title: APP_TITLE,
    login: 'Connexion',
    logout: 'Déconnexion',
  },
  footer: {
    contactUs: "Besoin d'aide? Contactez-nous.",
    contactEmail: 'mailto:support.raam@joinhealthq.com',
    privacyPolicyTitle: 'Politique de confidentialité',
    privacyPolicy: 'politique de confidentialité',
    privacyPolicyText,
    termsOfServiceTitle: 'Modalités de service',
    termsOfService: 'modalités de service',
    termsOfServiceText,
  },
  signin: {
    please_sign_in: 'Veuillez vous connecter...',
    title: 'Connexion',
    message:
      "Utilisez vos informations d'identification pour vous connecter à la salle d'attente virtuelle.",
    email_label: 'Email:',
    email_placeholder: 'veuillez entrer votre adresse e-mail',
    password_label: 'Mot de passe:',
    password_placeholder: 'veuillez entrer le mot de passe',
    remember_me: 'Se souvenir de moi',
    button_label: 'Connexion',
    forgot_password: 'Mot de passe oublié?',
    oidc: {
      title: 'Bienvenue sur la configuration du réseau RAAM',
      select_method: 'Choisissez votre méthode de connexion',
      continue: 'Continuer',
      signout: 'Déconnexion',
      bookmark_hint:
        "Si vous avez l'intention de revenir, n'oubliez pas de mettre cette page dans vos favoris",
    },
    error: {
      title: 'Impossible de se connecter',
      message1:
        "Une erreur s'est produite lors de votre tentative de connexion. Veuillez réessayer en vous assurant que vous utilisez les bons identifiants.",
      message2:
        "Si le problème persiste, veuillez contacter l'administrateur de votre clinique RAAM inQ pour obtenir de l'aide.",
      switch_user:
        "Pour vous connecter à l'aide d'autres identifiants, assurez-vous que vous êtes déconnecté du compte courant.",
      switch_user_link: 'Déconnectez-vous ici',
    },
  },
  forgot_password: {
    title: 'Mot de passe oublié',
    message:
      'Entrez votre courriel pour commencer pour réinitialiser votre mot de passe.',
    email_label: 'Email:',
    email_placeholder: 'veuillez entrer votre adresse e-mail',
    button_label: {
      cancel: 'Annuler',
      submit: 'Soumettre',
    },
    success:
      "Un email a été envoyé à l'adresse {{email}} avec les instructions pour modifier votre mot de passe.",
  },
  change_password: {
    title: 'Modifier le mot de passe',
    message: 'Nouveau mot de passe',
    password_label: 'Mot de passe',
    confirm_password_label: 'Confirmez votre mot de passe',
    button_label: {
      cancel: 'Annuler',
      change: 'Modifier le mot de passe',
    },
    success:
      'Vous pouvez maintenant |vous connecter| avec votre nouveau mot de passe.',
    success_sign_in: 'Vous Connecter',
    error: {
      min: 'Le mot de passe doit contenir au moins 8 caractères',
      lowercase:
        'Le mot de passe doit contenir au moins un caractère minuscule',
      uppercase:
        'Le mot de passe doit contenir au moins un caractère majuscule',
      digits: 'Le mot de passe doit contenir au moins un chiffre',
      spaces: "Le mot de passe ne doit pas contenir d'espaces",
    },
  },
  not_implemented: 'État "{{connectionState}}" non-implanté',
  reports: {
    registrationMethodToday: "Aujourd'hui",
    registrationMethodYesterday: 'Hier',
    registrationMethodThisMonth: 'Ce mois-ci',
    registrationMethodThisWeek: 'Cette semaine',
    form: {
      title: 'Rapports sur les réseaux',
      downloadUsageReports: "Télécharger les rapports d'utilisation",
      description:
        'Sélectionnez le type et la durée appropriés pour les données requises pour le rapport et téléchargez-les sous forme de fichier .csv pour chaque',
      isVisitReport: {
        label: 'Visites clients',
        hint: 'Ce rapport montrera les détails de la visite du client.',
      },
      isDenialReport: {
        label: "Clients refusés d'entrer",
        hint: 'Ce rapport montrera les clients qui ont été refusés dans la clinique.',
      },
      isQueueReport: {
        label: "Temps de file d'attente",
        hint: "Ce rapport montrera l'expérience du client dans la file d'attente.",
      },
      isEventLog: {
        label: "Journal d'évènements",
        hint: 'Ce rapport montrera comment le clinicien a interagi avec le patient.',
      },
      aggregateResult: {
        label:
          'Compiler les rapports de manière à ce que les données des cliniques sélectionnées se trouvent dans un seul fichier pour chaque type de rapport.',
      },
      reportDuration: {
        label: 'Durée du rapport:',
        option1: 'La semaine dernière',
        option2: 'Ce mois-ci',
        option3: 'Le mois dernier',
        option4: 'Plage de dates personnalisée',
      },
      customDateRange: {
        text: 'Sélectionnez les dates de début et de fin du rapport',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        header: 'Plage de dates personnalisée',
        error: {
          noDateRange: 'Plage de dates non sélectionnée',
          invalidDate: 'Date invalide',
          dateInFuture: 'Date choisie dans le futur',
          rangeNotSane: 'Fin avant début',
          rangeTooLong: 'La période doit être inférieure à {{max}} jours',
          noStartDate: "Aucune date de début n'a été sélectionnée",
        },
      },
      networkClinic: {
        title: 'Clinique du réseau',
        subheading:
          'Sélectionnez la ou les cliniques pour lesquelles vous souhaitez obtenir des rapports',
      },
      selectClinic: {
        title: 'Réseau de cliniques',
        subheading:
          'Sélectionnez la ou les cliniques pour lesquelles vous souhaitez obtenir des rapports.',
        primary: 'Clinique',
        tooltip: {
          selectAll: 'Tout sélectionner',
          clearAll: 'Tout effacer',
        },
      },
      warning:
        'Veuillez noter que même si ces données ont été nettoyées le mieux possible, elles peuvent encore contenir un certain niveau de PHI et ne doivent être téléchargées et utilisées que conformément aux procédures de sécurité des données de votre organisation.',
      submit: {
        button: 'Télécharger les rapports sélectionnés',
      },
    },
    main: {
      satisfactionScore0: 'Pas de score de satisfaction',
      satisfactionScore1: '1 - Très insatisfait',
      satisfactionScore2: '2 - Plutôt insatisfait',
      satisfactionScore3: '3 - Ni insatisfait ni satisfait',
      satisfactionScore4: '4 - Assez satisfait',
      satisfactionScore5: '5 - Très satisfait',
    },
  },
  email_reports: {
    title: 'Rapports mensuels',
    description:
      'Ces personnes recevront des rapports mensuels par courriel le premier jour de chaque mois.',
    table: {
      aria: 'Tableau des destinataires des rapports par courriel',
      name: 'Nom',
      email: 'Courriel',
      network_usage_report: "Rapport sur l'utilisation du réseau",
      client_visits: 'Visites des clients',
      denied_entry: "Clients refusés d'entrer",
      queue_times: "Temps de file d'attente",
    },
  },
  recipient: {
    management: {
      add_title: 'Ajouter un destinataire',
      edit_title: 'Modifier le destinataire',
      add_description: 'Saisissez les détails du nouveau destinataire.',
      edit_description: 'Entrez les détails du destinataire.',
    },
    error: {
      add: `Une erreur s'est produite lors de l'ajout du destinataire.
        Veuillez vérifier les détails du destinataire.
        Si l'erreur persiste, contactez votre administrateur.`,
      update: `Une erreur s'est produite lors de la modification du destinataire.
        Veuillez vérifier les détails du destinataire.
        Si l'erreur persiste, contactez votre administrateur.`,
      remove: `Un problème s'est produit lors de la suppression du destinataire.
        Si l'erreur persiste, contactez votre administrateur.`,
    },
  },
  user: {
    management: {
      title: 'Gestion des utilisateurs',
      description:
        "Ces utilisateurs sont les administrateurs de l'application Access RAAM Network.",
      table: {
        aria: 'Tableau des utilisateurs administrateurs',
        name: 'Nom',
        affiliation: 'Clinique',
        email: 'Courriel',
      },
      error: `Une erreur s'est produite lors de l'ajout ou de la modification de l'utilisateur.
        Veuillez vérifier les détails de l'utilisateur.
        Si l'erreur persiste, contactez votre administrateur.`,
    },
    add_title: 'Ajouter un utilisateur',
    edit_title: "Modifier l'utilisateur",
    add_description: 'Saisissez les détails du nouvel utilisateur.',
    edit_description: "Entrez les détails de l'utilisateur.",
    name: 'Nom',
    clinic_name: 'Nom de la clinique',
    email: 'Courriel',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    edit: 'Modifier',
    update: 'Modifier',
    delete: 'Supprimer',
    confirm: {
      title: "Supprimer l'utilisateur?",
      body: 'Êtes-vous sûr de vouloir supprimer {{userName}}? Cette action ne peut pas être annulée.',
      confirm: 'Confirmer',
      cancel: 'Annuler',
    },
  },
};
