import { ComponentsOverrides, Theme } from '@mui/material';
import { COLOURS } from '../constants/constants';
import { spacing } from './constants';

const titleStyleOverrides: ComponentsOverrides<Theme>['MuiDialogTitle'] = {
  root: {
    margin: 'auto',
    fontStyle: 'italic',
    color: COLOURS.blue.medium,
  },
};

const contentStyleOverrides: ComponentsOverrides<Theme>['MuiDialogContent'] = {
  root: {
    '.error-dialog &': {
      '*:not(:first-child)': {
        'margin-top': `${spacing * 3}px`,
      },
    },
  },
};

const actionStyleOverrides: ComponentsOverrides<Theme>['MuiDialogActions'] = {
  root: {
    margin: 'auto',
  },
};

export const MuiDialogTitle = {
  styleOverrides: titleStyleOverrides,
};

export const MuiDialogContent = {
  styleOverrides: contentStyleOverrides,
};

export const MuiDialogActions = {
  styleOverrides: actionStyleOverrides,
};
