import { ConnectionService } from 'hive-client-utils';
import { useConnectionState } from 'hive-react-utils';
import { memo, useMemo } from 'react';
import Content from '../pages/Content';
import Connecting from './Connecting';
import Disconnected from './Disconnected';
import Disconnecting from './Disconnecting';
import NotImplemented from './NotImplemented';

function ContentByStateFactory({
  mainContent,
}: {
  mainContent: JSX.Element;
}): JSX.Element {
  const connectionState = useConnectionState() || 'default';

  const ComponentByState = useMemo(() => {
    switch (connectionState) {
      case ConnectionService.ConnectionState.DISCONNECTED:
        return () => <Disconnected />;
      case ConnectionService.ConnectionState.DISCONNECTING:
        return () => <Disconnecting />;
      case ConnectionService.ConnectionState.CONNECTING:
      case ConnectionService.ConnectionState.CONNECTING_FROM_TOKEN:
        return () => <Connecting />;
      case ConnectionService.ConnectionState.CONNECTED:
      case ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN:
        return () => <Content mainContent={mainContent} />;
      default:
        return () => <NotImplemented />;
    }
  }, [connectionState, mainContent]);

  return <ComponentByState />;
}

export default memo(ContentByStateFactory);
