import { ComponentsOverrides, Theme } from '@mui/material';
import { COLOURS } from '../constants/constants';

const styleOverrides: ComponentsOverrides<Theme>['MuiTypography'] = {
  h1: {
    color: COLOURS.blue.medium,
    '&.download-report__title': {
      fontStyle: 'italic',
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '1.5',
      letterSpacing: '0.1px',
    },
  },

  h2: {
    color: COLOURS.blue.medium,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.125',
    fontSize: '1rem',
  },

  h3: {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '1.25rem',
    color: COLOURS.blue.medium,
  },

  h4: {
    '.oidc &': {
      color: COLOURS.blue.medium,
      'font-style': 'italic',
      'font-weight': '700',
    },
  },

  h5: {
    fontStyle: 'italic',

    '.oidc &': {
      color: COLOURS.blue.medium,
      'font-style': 'italic',
      'font-weight': '500',
    },

    '.oidc &.bookmark-hint': {
      'border-radius': '8px',
      'background-color': 'rgba(255, 255, 255, 0.75)',
      padding: '32px',
      margin: '24px',
    },
  },

  body1: {
    'header .MuiIconButton-root &': {
      textTransform: 'capitalize',
    },
  },
};

export const MuiTypography = {
  styleOverrides,
};
