import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadPHIWarning } from '../components/DownloadPHIWarning';
import { EmailReportTable } from '../components/EmailReportTable';
import { MainContentBox } from '../components/MainContentBox/MainContentBox';
import { StyledTitle } from '../components/MainContentBox/StyledTitle';
import {
  INIT_RECIPIENT_DATA,
  RecipientDialog,
} from '../components/RecipientDialog';

export function EmailReportPage() {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <MainContentBox>
      <StyledTitle>{t('email_reports.title')}</StyledTitle>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={12}
      >
        <Typography>{t('email_reports.description')}</Typography>
        <Button variant="outlined" onClick={() => setOpenDialog(true)}>
          {t('user.add_title')}
        </Button>
      </Stack>
      <EmailReportTable />
      <DownloadPHIWarning />
      <RecipientDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        isEdit={false}
        data={INIT_RECIPIENT_DATA}
      />
    </MainContentBox>
  );
}
