import { ReportDurationOptions } from '../types';

export const COLOURS = {
  blue: {
    dark: '#02253c',
    medium: '#26436e',
    light: '#92a1b7',
    bright: '#077ad3',
  },
  grey: {
    light: '#eaeaea',
    mediumish: '#bfbfbf',
    medium: '#939393',
    dark: '#393939',
    cadet: {
      light: '#e3e7ed',
      medium: '#91a0b6',
    },
  },
  red: {
    light: '#FFE9E9',
    medium: '#E86060',
    dark: '#c20000',
  },
  orange: '#fa7000',
  green: '#448e1b',
  white: '#ffffff',
  black: '#000000',
};

export const REPORT_FORM = [
  {
    label: 'reports.form.isVisitReport.label',
    name: 'isVisitReport',
    helperText: 'reports.form.isVisitReport.hint',
  },
  {
    label: 'reports.form.isDenialReport.label',
    name: 'isDenialReport',
    helperText: 'reports.form.isDenialReport.hint',
  },
  {
    label: 'reports.form.isQueueReport.label',
    name: 'isQueueReport',
    helperText: 'reports.form.isQueueReport.hint',
  },
] as const;

export const REPORT_AGGREGATE = {
  label: 'reports.form.aggregateResult.label',
  name: 'aggregateResult',
  helperText: 'reports.form.aggregateResult.hint',
} as const;

export const REPORT_DURATION_OPTIONS = [
  {
    value: ReportDurationOptions.LAST_WEEK,
    label: 'reports.form.reportDuration.option1',
  },
  {
    value: ReportDurationOptions.THIS_MONTH,
    label: 'reports.form.reportDuration.option2',
  },
  {
    value: ReportDurationOptions.LAST_MONTH,
    label: 'reports.form.reportDuration.option3',
  },
  {
    value: ReportDurationOptions.CUSTOM,
    label: 'reports.form.reportDuration.option4',
  },
] as const;
