import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DataContext from '../../contexts/DataContext';
import { useDownloadReportContext } from '../../hooks/useDownloadReportContext';
import StyledCheckbox from './StyledCheckbox';
import StyledList from './StyledList';

function renderCheckbox(selectedClinics: string[]) {
  return selectedClinics.length !== 0 ? (
    <IndeterminateCheckBoxIcon color="secondary" />
  ) : (
    <CheckBoxOutlineBlankIcon color="secondary" />
  );
}

export function SelectNetworkClinic(): JSX.Element {
  const { t } = useTranslation();

  const { dfd } = useContext(DataContext);

  const { state, setSelectedClinic } = useDownloadReportContext();
  const { selectedClinics } = state;

  const handleToggle = useCallback(
    (value: string) => () => {
      const updatedItems = selectedClinics.includes(value)
        ? selectedClinics.filter((item) => item !== value)
        : [...selectedClinics, value];

      setSelectedClinic(updatedItems);
    },
    [selectedClinics, setSelectedClinic]
  );

  const handleToggleAll = useCallback(() => {
    const updatedItems =
      selectedClinics.length === dfd.length
        ? []
        : dfd.map((clinic) => clinic.properties.orgId);

    setSelectedClinic(updatedItems);
  }, [selectedClinics.length, setSelectedClinic, dfd]);

  const toolTipTitle = useCallback(() => {
    if (selectedClinics.length === 0) {
      return t('reports.form.selectClinic.tooltip.selectAll');
    } else if (selectedClinics.length === dfd.length) {
      return t('reports.form.selectClinic.tooltip.clearAll');
    } else {
      return t('reports.form.selectClinic.tooltip.selectAll');
    }
  }, [selectedClinics.length, t, dfd]);

  return (
    <StyledList
      subheader={
        <ListItem
          disablePadding
          divider
          sx={{
            bgcolor: (theme) => theme.palette.primary.main,
          }}
        >
          <ListItemButton
            role={undefined}
            onClick={handleToggleAll}
            dense
            disableRipple
          >
            <ListItemIcon>
              <Tooltip title={toolTipTitle()} arrow placement="top">
                <StyledCheckbox
                  checked={selectedClinics.length === dfd.length}
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': 'select-all-clinics',
                  }}
                  checkedIcon={<CheckBoxIcon color="secondary" />}
                  icon={renderCheckbox(selectedClinics)}
                />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              id="select-all-clinics"
              primary={t('reports.form.selectClinic.primary')}
              primaryTypographyProps={{
                sx: {
                  color: (theme) => theme.palette.primary.contrastText,
                },
              }}
            />
          </ListItemButton>
        </ListItem>
      }
    >
      {dfd.map((value, index) => {
        const labelId = `checkbox-list-label-${value.properties.name}`;

        return (
          <ListItem
            key={value.properties.name}
            disablePadding
            {...(index !== dfd.length - 1 && { divider: true })}
          >
            <ListItemButton
              role={undefined}
              onClick={handleToggle(value.properties.orgId)}
              dense
            >
              <ListItemIcon>
                <StyledCheckbox
                  edge="start"
                  checked={selectedClinics.includes(value.properties.orgId)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.properties.name} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </StyledList>
  );
}

export default memo(SelectNetworkClinic);
