import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function DownloadPHIWarning() {
  const { t } = useTranslation();

  return (
    <Box className="warning-box">
      <Typography>{t('reports.form.warning')}</Typography>
    </Box>
  );
}
