import moment from 'moment';
import { DateRange } from 'react-day-picker';

const maxDateRangeLength = 365;

export function dateRangeExists(
  range: DateRange | undefined
): range is { from: Date; to?: Date } {
  return Boolean(range && range.from);
}

export function dateRangeIsInOrder(range: DateRange | undefined): boolean {
  if (!dateRangeExists(range)) return false;

  if (range.to) {
    return moment(range.from).isSameOrBefore(range.to);
  }

  return true;
}

export function dateInTheFuture(date: Date | undefined): boolean {
  // Note: Returns `true` if date is undefined
  return date ? moment().isSameOrBefore(date) : false;
}

export function dateRangeTooLong(range: DateRange | undefined): boolean {
  if (!dateRangeExists(range) || !range.to) return false;

  return (
    -moment(range.from).diff(range.to) >=
    maxDateRangeLength * 24 * 60 * 60 * 1000
  );
}

/**
 * Checks that a date range is defined and has a start date, no dates in the future, and a length of less than `maxDateRangeLength` days.
 */
export function dateRangeIsValid(range: DateRange | undefined): boolean {
  return (
    dateRangeIsInOrder(range) &&
    !dateInTheFuture(range?.from) &&
    !dateInTheFuture(range?.to) &&
    !dateRangeTooLong(range)
  );
}
