import _ from 'lodash';
import _fp from 'lodash/fp';
import { useMemo } from 'react';
import {
  IOidcProvider,
  SCREEN_OIDC_ERROR,
  SCREEN_OIDC_LOGIN,
  SCREEN_OIDC_SUCCESS,
  STORAGE_KEY_OIDC_PROVIDER_ID,
} from '../utils/oidc';

const USE_OIDC = (window as any)?._env_?.REACT_APP_USE_OIDC === 'true';
const HIVE_HOST = (window as any)?._env_?.REACT_APP_HIVE_HOST;
const HIVE_ORG_NAME = (window as any)?._env_?.REACT_APP_HIVE_ORG_NAME;
const HIVE_APP_ID = (window as any)?._env_?.REACT_APP_HIVE_APP_ID;

const OIDC_PROVIDERS_RAW: IOidcProvider[] | undefined = (window as any)?._env_
  ?.REACT_APP_OIDC_PROVIDERS;

const DEFAULT_PROVIDERS: IOidcProvider[] = [
  {
    // No ID! Empty string is the default provider (legacy)
    id: '',
    type: 'raam',
    label: 'RAAM user',
    loginUri: '',
  },
];

export function generateRedirectUrl(path?: string, providerId?: string) {
  const url = new URL(window.location.toString());
  url.pathname = `${path || ''}`;
  url.search = '';
  url.hash = '';

  if (providerId) {
    url.searchParams.append('providerId', providerId);
  }

  return url.toString();
}

const OIDC_PROVIDERS: IOidcProvider[] = !OIDC_PROVIDERS_RAW?.length
  ? DEFAULT_PROVIDERS
  : OIDC_PROVIDERS_RAW;

interface BuildUriOptions {
  callback: string;
  errorCb: string;
  [key: string]: string | number;
}

const buildUri = (
  provider: IOidcProvider,
  operation: string,
  rawOptions: Partial<BuildUriOptions> = {}
) => {
  const options: BuildUriOptions = {
    callback: SCREEN_OIDC_SUCCESS,
    errorCb: SCREEN_OIDC_ERROR,
    ...rawOptions,
  };

  // Make the callback and errorCb full URLs
  options.callback = generateRedirectUrl(options.callback, provider.id);
  options.errorCb = generateRedirectUrl(options.errorCb, provider.id);

  const path = _fp.flow([
    // multi-line
    (op: string) => ['oidc', op, HIVE_ORG_NAME, HIVE_APP_ID, provider.id],
    _fp.compact,
    _fp.join('/'),
  ])(operation);

  const uri = new URL(`${HIVE_HOST}/${path}`);

  for (const [key, value] of Object.entries(options)) {
    uri.searchParams.append(key, value.toString());
  }

  return uri;
};

const buildLoginUri = (provider: any) => buildUri(provider, 'login');

const buildLogoutUri = (provider: any) =>
  buildUri(provider, 'logout', { callback: SCREEN_OIDC_LOGIN });

export function useOidcProviders(): IOidcProvider[] {
  return useMemo(() => {
    if (!USE_OIDC) {
      return [];
    }

    return _fp.flow([
      _fp.map((p: IOidcProvider) => ({
        ...p,
        loginUri: buildLoginUri(p),
        logoutUri: buildLogoutUri(p),
      })),
    ])(OIDC_PROVIDERS);
  }, []);
}

export function useOidcProviderById(
  providerId: string
): IOidcProvider | undefined {
  const oidcProviders = useOidcProviders();

  return useMemo(
    () => _.find(oidcProviders, { id: providerId }),
    [oidcProviders, providerId]
  );
}

export function useOidcProviderFromStorage(): IOidcProvider | undefined {
  const providerId = localStorage.getItem(STORAGE_KEY_OIDC_PROVIDER_ID) || '';
  return useOidcProviderById(providerId);
}
