import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmDialogProps {
  open: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  userName: string;
}
function ConfirmDialog({
  open: openDialogue,
  onConfirm,
  onCancel: onDeny,
  userName,
}: ConfirmDialogProps) {
  const { t } = useTranslation();
  const handleCancel = () => {
    if (onDeny) {
      onDeny();
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Dialog open={openDialogue} onClose={handleCancel}>
      <DialogTitle>{t('user.confirm.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('user.confirm.body', { userName })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary">
          {t('user.confirm.confirm')}
        </Button>
        <Button onClick={handleCancel} color="primary" autoFocus>
          {t('user.confirm.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
