import {
  ChangeEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useEffect,
} from 'react';
import { ReportDurationOptions } from '../types';
import { DateRangeState } from './useDateRangeContext';

import { useConnection } from 'hive-react-utils';
import { downloadReports, IDownloadRequest } from '../utils';
import DataContext from '../contexts/DataContext';

import _ from 'lodash';

export interface FormState {
  loading: boolean;
  formValues: {
    isVisitReport: boolean;
    isDenialReport: boolean;
    isQueueReport: boolean;
    aggregateResult: boolean;

    reportDurationOption: ReportDurationOptions;
  };
  selectedClinics: string[];
}

type FormAction =
  | { type: 'SET_LOADING'; payload: boolean }
  | {
      type: 'SET_FORM_VALUE';
      payload: {
        name: keyof FormState['formValues'];
        value: boolean;
      };
    }
  | {
      type: 'SET_SELECTED_CLINICS';
      payload: Array<string>;
    };

interface FormContextValue {
  state: FormState;
  handleReportTypeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (dateRange: DateRangeState) => void;
  setSelectedClinic: (clinic: string[]) => void;
  noSelectedClinic: boolean;
}

const INITIAL_STATE: FormState = {
  loading: false,

  formValues: {
    isVisitReport: true,
    isDenialReport: true,
    isQueueReport: true,
    aggregateResult: true,
    reportDurationOption: ReportDurationOptions.CUSTOM,
  },

  selectedClinics: [],
};

const FormContext = createContext<FormContextValue>({
  state: INITIAL_STATE,
  handleReportTypeChange: () => {},
  handleSubmit: () => {},
  setSelectedClinic: () => {},
  noSelectedClinic: true,
});

FormContext.displayName = 'DownloadReportFormContext';

function formReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };

    case 'SET_FORM_VALUE':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.payload.name]: action.payload.value,
        },
      };

    case 'SET_SELECTED_CLINICS':
      return {
        ...state,
        selectedClinics: action.payload,
      };

    default:
      return state;
  }
}

export function DownloadReportFormContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);

  const connection = useConnection();

  const handleReportTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // check if event.target.name is a key of FormState['formValues']
      if (!(event.target.name in state.formValues)) {
        console.error('Invalid event.target.name while changing report type');
        return;
      }

      dispatch({
        type: 'SET_FORM_VALUE',
        payload: {
          name: event.target.name as keyof FormState['formValues'],
          value: event.target.checked,
        },
      });
    },
    [state.formValues]
  );

  const { dfd } = useContext(DataContext);

  useEffect(() => {
    dispatch({
      type: 'SET_SELECTED_CLINICS',
      payload: dfd.map((i) => i.properties.orgId),
    });
  }, [dfd]);

  const handleSubmit = useCallback(
    (dateRangeState: DateRangeState) => {
      if (dateRangeState.selectedDateRange) {
        let payload: IDownloadRequest = {
          visitReport: state.formValues.isVisitReport,
          denialReport: state.formValues.isDenialReport,
          queueReport: state.formValues.isQueueReport,
          from: dateRangeState.selectedDateRange.from?.toISOString() || '',
          to: dateRangeState.selectedDateRange.to?.toISOString() || '',
        };

        let clinics = _.map(state.selectedClinics, (orgId) => ({
          orgId,
          name: _.get(
            _.find(dfd, { properties: { orgId } }),
            'properties.name',
            ''
          ),
        }));

        dispatch({ type: 'SET_LOADING', payload: true });
        downloadReports(
          connection,
          clinics,
          payload,
          state.formValues.aggregateResult
        ).then(() => dispatch({ type: 'SET_LOADING', payload: false }));
      }
    },
    [state.formValues, state.selectedClinics, connection, dfd]
  );

  const setSelectedClinic = useCallback(
    (clinic: string[]) => {
      dispatch({
        type: 'SET_SELECTED_CLINICS',
        payload: clinic,
      });
    },
    [dispatch]
  );

  const noSelectedClinic = useMemo(() => {
    return _.size(state.selectedClinics) === 0;
  }, [state.selectedClinics]);

  const ctxValue = useMemo(
    () => ({
      state,
      handleReportTypeChange,
      handleSubmit,
      setSelectedClinic,
      noSelectedClinic,
    }),
    [
      state,
      handleReportTypeChange,
      handleSubmit,
      setSelectedClinic,
      noSelectedClinic,
    ]
  );

  return (
    <FormContext.Provider value={ctxValue}>{children}</FormContext.Provider>
  );
}

export function useDownloadReportContext() {
  const ctx = useContext(FormContext);

  if (!ctx) {
    throw new Error(
      'useDownloadReportContext must be used within a DownloadReportFormContextProvider'
    );
  }

  return ctx;
}
