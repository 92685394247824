import _ from 'lodash';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ConnectionService } from 'hive-client-utils';

import moment from 'moment';

export interface IDownloadRequest {
  visitReport: boolean;
  denialReport: boolean;
  queueReport: boolean;
  from?: string;
  to: string;
}

type Reports = {
  header: string[];
  data: any[];
};

type Clinic = {
  orgId: string;
  name: string;
};

type DataContent = {
  visitReport?: Reports;
  denialReport?: Reports;
  queueReport?: Reports;
  nextFrom?: string;
};

function createCSV(header: string[], data: string[]): string {
  return _.join(
    [_.join(header, ','), ..._.map(data, (row) => _.join(row, ','))],
    '\n'
  );
}

async function downloadReportFrom(
  connection: ConnectionService.Connection,
  orgId: string,
  initialRequest: IDownloadRequest
): Promise<any> {
  let mergedData: DataContent = {};
  let request: IDownloadRequest = _.clone(initialRequest);

  do {
    let content = (await connection.bee.api.invoke(
      'network.downloadReports',
      orgId,
      request
    )) as DataContent;
    request.from = content.nextFrom;

    _.each(_.pull(_.keys(content), 'nextFrom', 'queryTo'), (key) => {
      let mData: Reports = _.get(mergedData, key) ?? { header: null, data: [] };
      let { data, header } = _.get(content, key);

      mData.header ??= header;
      mData.data.push(...data);

      _.set(mergedData, key, mData);
    });
  } while (request.from);

  return mergedData;
}

function processReportData(reportData: DataContent): any {
  return _.map(reportData, (r: Reports, key: string) => {
    let { header, data } = r;
    let s = createCSV(header, data);
    return [key, s];
  });
}

type ReportTuple = [string, DataContent];

function aggregateReport(reports: ReportTuple[], reportName: string) {
  return {
    header: ['Clinic Name', ..._.get(reports, [0, 1, reportName, 'header'])],
    data: _.flatten(
      _.map(reports, ([name, data]) =>
        _.flow([
          (o) => _.get(o, [reportName, 'data'], []),
          (d) => _.map(d, (i) => [name, ...i]),
        ])(data)
      )
    ),
  };
}

export async function downloadReports(
  connection: ConnectionService.Connection | undefined,
  clinics: Clinic[],
  initialRequest: IDownloadRequest,
  aggregateResult: boolean
): Promise<any> {
  if (!connection) {
    return;
  }

  try {
    const zip = new JSZip();
    let reports = [];
    for (const clinic of clinics) {
      let { orgId, name } = clinic;
      let reportData = await downloadReportFrom(
        connection,
        orgId,
        initialRequest
      );

      let entry: ReportTuple = [name, reportData];

      reports.push(entry);
    }

    if (aggregateResult) {
      let nR = [
        [
          'Aggregated Reports',
          _.pickBy({
            visitReport:
              initialRequest.visitReport &&
              aggregateReport(reports, 'visitReport'),
            denialReport:
              initialRequest.denialReport &&
              aggregateReport(reports, 'denialReport'),
            queueReport:
              initialRequest.queueReport &&
              aggregateReport(reports, 'queueReport'),
          }),
        ],
      ];

      reports = nR;
    }

    _.each(reports, (pair) => {
      let [name, reportData] = pair as ReportTuple;
      let data = processReportData(reportData);
      _.each(data, ([fileName, fileContent]) =>
        zip.file(
          `${!aggregateResult ? `${name}/` : ''}${fileName}.csv`,
          new Blob([fileContent])
        )
      );
    });

    if (clinics.length > 0) {
      let date = moment().format('DD-MM-YYYY');
      let fileName = aggregateResult
        ? `RAAM-Aggregated-Network-Reports-${date}.zip`
        : `RAAM-Network-Reports-${date}.zip`;
      zip.generateAsync({ type: 'blob' }).then((content: any) => {
        saveAs(content, fileName);
      });
    }
  } catch (e) {
    console.error('Error while downloading the report');
    // FUTURE: do proper error handling in the UI
  }
}
