import { ComponentsOverrides, Theme } from '@mui/material';
import { COLOURS } from '../constants/constants';

const styleOverrides: ComponentsOverrides<Theme>['MuiContainer'] = {
  root: {
    '&.oidc': {
      'max-width': 'unset',
      padding: 0,
      margin: 0,
      display: 'flex',
      'flex-grow': '1',
      'background-image': "url('/oidc-background.png')",
      'background-position': 'center',
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
    },

    '.warning-box': {
      width: '100%',
      border: `1px solid ${COLOURS.red.dark}`,
      borderRadius: '8px',
      backgroundColor: COLOURS.red.light,
      padding: '16px',
      p: {
        color: COLOURS.red.dark,
        fontStyle: 'italic',
      },
    },
  },
};

export const MuiContainer = {
  styleOverrides,
};
