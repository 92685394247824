import _ from 'lodash';
import _fp from 'lodash/fp';
import { Key, useCallback, useEffect, useState } from 'react';
import { useOidcProviders } from '../../hooks/useOidcProviders';

import { useTranslation } from 'react-i18next';

import { ErrorDialog, ErrorDialogMessage } from '../../components/ErrorDialog';
import { base64ToJSON } from '../../utils/base64';
import { ReactComponent as MicrosoftLogo } from './assets/Microsoft_icon.svg';
import { ReactComponent as ShmbLogo } from './assets/shmb.svg';
import {
  ReactComponent as DefaultLogo,
  ReactComponent as RaamLogo,
} from './assets/inQ_icon.svg';
import { IOidcProvider } from '../../utils/oidc';
import {
  Button,
  Card,
  CardContent,
  Container,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';

const LOGOS_BY_TYPE = {
  entraid: MicrosoftLogo,
  raam: RaamLogo,
  shmb: ShmbLogo,
  default: DefaultLogo,
};

const getProviderLogo = _fp.flow([
  _fp.get('type'),
  _fp.toLower,
  _fp.getOr(LOGOS_BY_TYPE.default, _fp.__, LOGOS_BY_TYPE),
]);

interface OidcProviderProps {
  provider: IOidcProvider;
}

function OidcProvider({ provider }: OidcProviderProps): JSX.Element {
  const { t } = useTranslation();
  const Logo = getProviderLogo(provider);

  const [error, setError] = useState<string | undefined>();
  const [errorProviderId, setErrorProviderId] = useState('');
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const e = params.get('error') || params.get('message') || '';
    const providerId = params.get('providerId') || '';
    setError(e);
    if (e) {
      setErrorProviderId(providerId);
    }
  }, []);

  const signin = useCallback(() => {
    if (!provider.loginUri) {
      return;
    }

    window.location.assign(provider.loginUri);
  }, [provider.loginUri]);

  const signout = useCallback(() => {
    if (!provider.logoutUri) {
      return;
    }

    window.location.assign(provider.logoutUri);
  }, [provider.logoutUri]);

  return (
    <Card>
      <CardContent>
        <Logo />

        <Typography color="primary">{t(provider.label)}</Typography>

        <Button variant="contained" onClick={signin}>
          {t('signin.oidc.continue')}
        </Button>

        {error && errorProviderId === provider.id && provider.logoutUri && (
          <Link color="secondary" onClick={signout}>
            {t('signin.oidc.signout')}
          </Link>
        )}
      </CardContent>
    </Card>
  );
}

interface SwitchUserProps {
  key?: Key;
  provider: IOidcProvider;
}

function SwitchUser({ key, provider }: SwitchUserProps): JSX.Element | null {
  const { t } = useTranslation();

  if (!provider.logoutUri) {
    return null;
  }

  return (
    <Typography key={`${key}_label`}>
      {t('signin.error.switch_user')}
      &nbsp;
      <Link key={key} href={provider.logoutUri.toString()}>
        {t('signin.error.switch_user_link', { provider: provider.label })}
      </Link>
    </Typography>
  );
}

export function Oidc(): JSX.Element | null {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const providers = useOidcProviders();
  const { t } = useTranslation();

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [defaultErrorMessages] = useState<ErrorDialogMessage[]>([
    t('signin.error.message1'),
    t('signin.error.message2'),
  ]);
  const [errorMessages, setErrorMessages] = useState<ErrorDialogMessage[]>([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get('status') || params.get('statusCode') || 200;
    const error = params.get('error') || params.get('message') || '';
    const details = base64ToJSON(params.get('details'));
    const providerId = params.get('providerId') || '';
    const provider = _.find(providers, { id: providerId });

    if (error || status !== 200) {
      console.error('OIDC Error', { status, error, details });
      setErrorDialogOpen(true);

      const newMessages: ErrorDialogMessage[] = [...defaultErrorMessages];
      if (provider) {
        newMessages.push(<SwitchUser key={provider.id} provider={provider} />);
      }
      setErrorMessages(newMessages);

      // Remove the query parameters
      setSearchParams('');
    }
  }, [location, t, providers, defaultErrorMessages, setSearchParams]);

  const onErrorDialogClose = useCallback(() => {
    setErrorDialogOpen(false);
    setErrorMessages([]);
  }, []);

  if (_.isEmpty(providers)) {
    return null;
  }

  return (
    <Container className="oidc">
      <Stack
        alignItems="center"
        justifyContent="space-between"
        flexGrow={1}
        padding={0}
        margin={0}
      >
        <ErrorDialog
          className="oidc-error"
          open={errorDialogOpen}
          onClose={onErrorDialogClose}
          title={t('signin.error.title')}
          message={errorMessages}
          closeButtonLabel={t('signin.oidc.continue')}
        />

        <Stack spacing={4} direction="column">
          <Typography variant="h4" align="center">
            {t('signin.oidc.title')}
          </Typography>

          <Typography variant="h5" align="center">
            {t('signin.oidc.select_method')}
          </Typography>

          <Stack spacing={4} direction="row" justifyContent="center">
            {_.map(providers, (p) => (
              <OidcProvider key={p.id} provider={p} />
            ))}
          </Stack>
        </Stack>

        <Typography variant="h5" className="bookmark-hint">
          {t('signin.oidc.bookmark_hint')}
        </Typography>
      </Stack>
    </Container>
  );
}
