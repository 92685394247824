import moment from 'moment';
import {
  ChangeEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { DateRange } from 'react-day-picker';
import { DatesValid } from '../types';

type DateRangePickerAction =
  | {
      type: 'SET_DATE_RANGE';
      payload: DateRange | undefined;
    }
  | {
      type: 'SET_ARE_DATES_VALID';
      payload: DatesValid;
    };

export type DateRangeState = {
  selectedDateRange: DateRange | undefined;
  areDatesValid: DatesValid;
};

type ReportDateRangePickerContextValue = {
  state: DateRangeState;
  handleDatePickerChange: (
    event: ChangeEvent<HTMLInputElement>,
    dateRange: keyof DateRange
  ) => void;
  selectDateRangeHandler: (dateRange: DateRange | undefined) => void;
};

function dateRangeReducer(
  state: DateRangeState,
  action: DateRangePickerAction
): DateRangeState {
  switch (action.type) {
    case 'SET_DATE_RANGE':
      return {
        ...state,
        selectedDateRange: action.payload,
      };

    case 'SET_ARE_DATES_VALID':
      return {
        ...state,
        areDatesValid: {
          ...action.payload,
        },
      };

    default:
      throw new Error(`Unsupported action type: ${action}`);
  }
}

const INITIAL_STATE = {
  selectedDateRange: {
    from: moment().startOf('day').subtract(1, 'week').toDate(),
    to: moment().toDate(),
  },
  areDatesValid: {
    from: true,
    to: true,
  },
  handleDatePickerChange: () => {},
  selectDateRangeHandler: () => {},
};

const ReportDateRangePickerContext =
  createContext<ReportDateRangePickerContextValue>({
    state: INITIAL_STATE,
    handleDatePickerChange: () => {},
    selectDateRangeHandler: () => {},
  });

ReportDateRangePickerContext.displayName = 'ReportDateRangePickerContext';

export const ReportDateRangePickerContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(dateRangeReducer, INITIAL_STATE);

  const handleDatePickerChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, dateRangeKey: keyof DatesValid) => {
      const newDate = moment(event.target.value).toDate();
      const dateExists = !isNaN(newDate.valueOf());

      dispatch({
        type: 'SET_ARE_DATES_VALID',
        payload: {
          ...state.areDatesValid,
          [dateRangeKey]: dateExists,
        },
      });

      if (!dateExists) {
        return;
      }

      dispatch({
        type: 'SET_DATE_RANGE',
        payload: {
          from: undefined,
          ...state.selectedDateRange,
          [dateRangeKey]: newDate,
        },
      });
    },
    [state.areDatesValid, state.selectedDateRange]
  );

  const selectDateRangeHandler = useCallback(
    (dateRange: DateRange | undefined) => {
      dispatch({
        type: 'SET_DATE_RANGE',
        payload: dateRange,
      });
    },
    []
  );

  const ctxValue = useMemo(
    () => ({
      state,
      handleDatePickerChange,
      selectDateRangeHandler,
    }),
    [state, handleDatePickerChange, selectDateRangeHandler]
  );

  return (
    <ReportDateRangePickerContext.Provider value={ctxValue}>
      {children}
    </ReportDateRangePickerContext.Provider>
  );
};

export const useReportDateRangePickerContext = () => {
  const ctx = useContext(ReportDateRangePickerContext);

  if (!ctx) {
    throw new Error(
      'useReportDateRangePicker must be used within a ReportDateRangePickerProvider'
    );
  }

  return ctx;
};
