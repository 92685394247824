/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-multi-str */

const IS_ENTERPRISE = process.env.REACT_APP_ENTERPRISE === "true";
const supportEmail = IS_ENTERPRISE? `support.${process.env.REACT_APP_ENTERPRISE_NAME}@joinhealthq.com` : `support@joinhealthq.com`;

export let TermsOfService_en = "\
EFFECTIVE DATE: July 1, 2020<br><br> \
Welcome to HealthQ! HealthQ service (\"<b>Service</b>\") is provided by Macadamian Technologies (\"<b>Macadamian</b>\", \"<b>we</b>\", \"<b>us</b>\" ). By using the Service, you (\"<b>you</b>\") are agreeing to be bound by the following terms and conditions (\"<b>Terms</b>\") and to the Privacy Policy (the \"<b>Policy</b>\"), which is incorporated into these Terms by reference. Macadamian reserves the right to update and change these Terms without notice. Violation of any of the Terms below may result in the termination of your subscription and/or in the exercise of any other remedies of Macadamian detailed herein.<br>\
<br> \
By using the Service, you represent and warrant that you have reached the legal age of your place of residence to enter into a contract.<br> \
<br>CHANGES<br> \
<br> \
We reserve the right, at our sole discretion, to supplement, modify or replace these Terms from time to time (\"<b>Updated Terms</b>\") at our sole discretion. In such event and unless such change is made for judicial or administrative reason, we shall make reasonable commercial efforts to notify you of such Updated Terms in advance. By continuing to access or use our Service after those Updated Terms become effective, you agree to be bound by the Updated Terms. If you do not agree to the Updated Terms, please stop using the Service and cancel your subscription.<br> \
<br>PRIVACY AND COMMUNICATIONS<br> \
<br> \
You acknowledge and agree that Service may occasionally send you communications regarding your account or the Service via email. All matters relating to privacy are governed exclusively by the Policy.<br> \
<br>HYPERLINKS<br> \
<br> \
The Service may contain hyperlinks to external Internet sites or resources (the \"<b>External Sites</b>\"). You acknowledge and agree that Macadamian is not responsible for the availability of these External Sites, nor for the accuracy of the content, products or services available on these External Sites. Hyperlinks to External Sites do not imply any approval or endorsement by Macadamian of these External Sites. You acknowledge that you assume all risks arising from your use of the External Sites. By using the Service, you expressly release Macadamian from any liability arising from your use of any External Site.<br> \
<br>ACCOUNTS, PASSWORDS, AND SECURITY<br> \
<br> \
You must be a registered user and register your organization to access the Service. You are responsible for keeping your password secure. You will be solely responsible and liable for any activity that occurs under your user name.<br> \
<br>USE OF THE SERVICE</br> \
<br> \
Macadamian grants you a limited, non-exclusive, non-transferable right to access and use the Service in accordance with these Terms (\"<b>Authorized Purposes</b>\").<br> \
<br> \
You may not engage in any of the following prohibited activities:<br> \
<ol style=\"list-style-type:lower-alpha\"> \
<li>using the Service for purposes other than the Authorized Purposes;<br> \
<li>copying, distributing, or disclosing any part of the Service in any medium, including by any automated tool or technique with the exception of copying, distributing or disclosing information inputted by you or your users into the Service;<br> \
<li>access or use the Service in order to build a competitive product or service, to build a product using similar ideas, features, functions or graphics or to copy any ideas, features, functions or graphics of the Service;<br> \
<li>knowingly upload or distribute any files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Service;<br> \
<li>modify, disassemble, decompile, reverse engineer, adapt or create derivative works from the Service;<br> \
<li>probe, scan, test the vulnerability of, or circumvent any security mechanisms used by the sites, servers, or networks connected to the Service;<br> \
<li>knowingly take any action that imposes an unreasonably or disproportionately large load on the sites, servers, or networks connected to the Service;<br> \
<li>access or use, or attempt to do so, the data of any other subscribers to the Service besides yourself;<br> \
<li>maliciously reduce or impair the accessibility of the Service; or<br> \
<li>otherwise using the Service in contravention of any applicable law.<br> \
</ol> \
<br> \
Macadamian reserves the right to investigate any breach of these Terms and to take appropriate remedies to the fullest extent permitted by law.<br> \
<br>LIMITATION OF LIABILITY AND DISCLAIMER OF WARRANTIES<br> \
<br> \
To the maximum extent permitted by law, the Service Is Available “As Is.” You expressly understand and agree that: <br> \
<ol style=\"list-style-type:lower-alpha\"> \
<li>Your use of the service and the purchase and use of any services are all at your sole risk.<br> \
<li>The service is provided on an “as is” and “as available” basis. to the maximum extent permitted by law, Macadamian expressly disclaims all warranties and conditions of any kind, whether express or implied, including, but not limited to the implied warranties and conditions of merchantability, fitness for a particular purpose and non-infringement.</li> \
<li>Without limiting the generality of the foregoing, Macadamian does not warrant that (i) the service will meet all of your requirements; (ii) the service will be uninterrupted, timely, secure or error-free; or (iii) all errors in the software or service will be corrected.</li> \
<li>Any material downloaded or otherwise obtained through the use of the service is done at your own discretion and risk and you are solely responsible for any damage to your computer or other device or loss of data resulting from the download or use of any such material.</li> \
<li>No advice or information, whether oral or written, obtained by you from Macadamian or through or from the service shall create any warranty not expressly stated in these terms of service.</li> \
<li>The content accessed through the Service is for informational purposes only and is not a substitute for professional medical advice, diagnostic or treatment. You must not ignore or delay obtaining professional medical advice because of information you were provided through the Service.</li> \
<li>In no event will Macadamian or those for whom it is in law responsible be liable to you or any third party for indirect, incidental, special, punitive or consequential damages whatsoever, including, but not limited to, loss of profit or business or revenues, whether arising in contract, tort or otherwise.  Macadamian’s liability to you regarding direct damages resulting from the Service’s performance or non-performance under these Terms, and for any and all claims, shall be solely limited to direct damages and shall in no event exceed one (1) month of subscription fees to the Service in the aggregate.</li> \
<li>If any term of this Agreement is declared to be invalid, illegal, or unenforceable by a court of competent jurisdiction, the said terms will be severed from this Agreement and all other terms and conditions will remain in full force and effect.</li> \
<li>These Terms will be governed by and construed in accordance with the laws of the Province of Ontario, Canada, and Macadamian and you each irrevocably agree to submit to the exclusive jurisdiction of the courts of the Province of Ontario in connection with any disputes arising under these Terms.</li> \
</ol> \
<br>INDEMNIFICATION<br> \
<br> \
By using the Service, you agree to defend, indemnify and hold harmless Macadamian, its subsidiaries and related companies, and their respective officers, agents, directors, employees, licensors and assigns from and against all claims, causes of action, damages, obligations, losses, liabilities, costs or debt, and expenses (including attorneys' fees and costs) and all amounts paid in settlement arising from or relating to use of the Service, or your violation of the Terms or the rights of third parties. Macadamian may assume the exclusive defense and control of any matter for which you have agreed to indemnify Macadamian and you agree to assist and cooperate with Macadamian in the defense or settlement of any such matters.<br> \
<br>TERMINATION OF SERVICE<br> \
<br> \
We reserve the right to terminate your subscription at any time if you commit a material or persistent breach of these Terms which you fail to remedy (if remediable) within 14 days after the receipt of written notice requiring you to do so.  You also have the option of cancelling your subscription at any time without penalty.<br> \
<br> \
In the event of subscription cancellation you will lose all data related to your HealthQ subscription and users shall immediately cease use of the Service.<br> \
<br> \
These Terms’ provisions that by their nature should survive termination shall survive termination, including provisions on intellectual property, warranty disclaimers, and limitations of liability, indemnification.. Termination of your access to and use of the Service shall not relieve you of any obligations arising or accruing prior to termination or limit any liability that you otherwise may have to Macadamian or any third party.<br>\
<br>RIGHT TO MODIFY THE SERVICE<br> \
<br> \
We retain the right, in our sole discretion, to implement new elements as part of and/or ancillary to the Service, including changes that may affect the previous mode of operation of the Service. We expect that any such modifications will enhance the overall Service, but it is possible that you may not agree with us. We also reserve the right to establish limits to the nature or number of daily screens, the ability to customize the questionnaire, access to the dashboard, and impose other limitations at any time, with or without notice. For example, if you use Bronze plan, you will not enjoy all of the benefits provided to subscribers of the Gold plan.<br> \
<br>INTELLECTUAL PROPERTY<br> \
<br> \
Macadamian claims no intellectual property rights over the content you provide to the Service, it being agreed, however, that Macadamian is free to use, profit, disclose, publish, keep secret or otherwise exploit any comments, suggestions or other ideas to improve or otherwise modify the Service (\"<b>Feedback</b>\"), without compensation or attribution to you or to any person behind this Feedback. You acknowledge that Macadamian owns all right, title and interest in and to the Service, including without limitation all intellectual property rights, and such rights are protected by Canadian and international intellectual property laws. You agree that you will not copy, reproduce, alter, modify, or create derivative works from the Service. Without limiting the generality of the foregoing:<br> \
<ol style=\"list-style-type:lower-alpha\"> \
<li>All trademarks (including words, expressions and logos, whether registered or unregistered) used by Macadamian for the purposes of distinguishing or so as to distinguish its own goods or services from those of others, are owned by Macadamian. Trademarks of Macadamian may not be used, reproduced or imitated, in whole or in part, without the prior written permission of Macadamian.<br> \
<li>All original works reproduced or contained in the Service are protected by copyright and owned by Macadamian. You acknowledge that it is an infringement of copyright for any person to do, without the consent of the owner of the copyright, anything that, by the applicable copyright statutes, only the owner of the copyright has the right to do.<br> \
<li>This Service or any part thereof may also be protected by industrial designs or patents. Macadamian reserves all rights to the Service not expressly granted.<br> \
</ol> \
<br>NO WAIVER<br> \
<br> \
The negligence or delay by Macadamian to exercise a right, remedy, recourse, power or privilege in accordance with the Terms does not constitute a waiver of such rights, recourses, powers or privileges. To be valid, a waiver must be made in writing and must be signed by Macadamian. A written waiver to a default cannot be interpreted as constituting a waiver to any other default or default of the same nature which may occur in the future.<br> \
<br>CONTACT US<br> \
<br> \
Users with questions about this Agreement or the Privacy Policy may contact Macadamian at <a href=\"mailto:"+supportEmail+"\">" + supportEmail + "</a> or 179 Promenade du Portage 4th Floor Gatineau, Canada, J8X 2K5<br> \
";

export let TermsOfService_fr = "\
Date d'entrée en vigueur: 1er juillet 2020<br><br>\
Bienvenue à HealthQ ! Le service HealthQ (\"<b>Service</b>\") est fourni par Macadamian Technologies (\"<b>Macadamian</b>\", \"<b>nous</b>\", \"<b>notre</b>\"). En utilisant le Service, vous (\"<b>vous</b>\") acceptez d'être lié par les termes et conditions suivants (\"<b>Conditions</b>\") et par la politique de confidentialité (la \"<b>Politique</b>\"), qui est incorporée dans ces Conditions par référence. Macadamian se réserve le droit de mettre à jour et de modifier les présentes conditions sans préavis. La violation de l'une des conditions ci-dessous peut entraîner la résiliation de votre abonnement et/ou dans l'exercice de tout autre recours de Macadamian détaillé dans le présent document.<br>\
<br>\
En utilisant le Service, vous déclarez et garantissez que vous avez atteint l'âge légal de votre lieu de résidence pour conclure un contrat.<br> \
<br>CHANGEMENTS<br> \
<br>\
Nous nous réservons le droit, à notre seule discrétion, de compléter, modifier ou remplacer ces conditions de temps en temps (\"<b>Conditions mises à jour</b>\") à notre seule discrétion. Dans ce cas, et à moins que cette modification ne soit effectuée pour des raisons judiciaires ou administratives, nous ferons des efforts commerciaux raisonnables pour vous informer à l'avance de la mise à jour des Conditions. En continuant à accéder ou à utiliser notre Service après l'entrée en vigueur de ces Conditions mises à jour, vous acceptez d'être lié par ces Conditions mises à jour. Si vous n'acceptez pas les conditions mises à jour, veuillez cesser d'utiliser le Service et annuler votre abonnement.<br>\
<br>VIE PRIVÉE ET COMMUNICATIONS<br>\
<br>\
Vous reconnaissez et acceptez que le Service puisse occasionnellement vous envoyer des communications concernant votre compte ou le Service par courrier électronique. Toutes les questions relatives à la vie privée sont régies exclusivement par la Politique.<br>\
<br>HYPERLINKS<br>\
<br>\
Le Service peut contenir des hyperliens vers des sites ou des ressources Internet externes (les \"<b>sites externes</b>\"). Vous reconnaissez et acceptez que Macadamian n'est pas responsable de la disponibilité de ces sites externes, ni de l'exactitude du contenu, des produits ou des services disponibles sur ces sites externes. Les hyperliens vers des sites externes n'impliquent aucune approbation ou approbation de ces sites externes par Macadamian. Vous reconnaissez que vous assumez tous les risques découlant de votre utilisation des sites externes. En utilisant le Service, vous libérez expressément Macadamian de toute responsabilité découlant de votre utilisation d'un site externe.<br>\
<br>COMPTES, MOTS DE PASSE ET SÉCURITÉ<br>\
<br>\
Vous devez être un utilisateur enregistré et enregistrer votre organisation pour accéder au Service. Vous êtes responsable de la sécurité de votre mot de passe. Vous serez seul responsable de toute activité qui se produira sous votre nom d'utilisateur.<br>\
<br>L'UTILISATION DU SERVICE<br>\
<br>\
Macadamian vous accorde un droit limité, non exclusif et non transférable d'accéder et d'utiliser le Service conformément aux présentes conditions (\"<b>fins autorisées</b>\").<br>\
<br>\
Vous ne pouvez vous livrer à aucune des activités interdites suivantes :<br>\
<ol style=\"list-style-type:lower-alpha\"> \
<li>l'utilisation du Service à des  fins autorisées ;<br>\
<li>la copie, la distribution ou la divulgation de toute partie du Service sur tout support, y compris par tout outil ou technique automatisé, à l'exception de la copie, de la distribution ou de la divulgation des informations saisies par vous ou vos utilisateurs dans le Service ;<br>\
<li>accéder ou utiliser le Service afin de créer un produit ou un Service concurrentiel, de créer un produit en utilisant des idées, des caractéristiques, des fonctions ou des graphiques similaires ou de copier toute idée, caractéristique, fonction ou graphique du Service ;<br>\
<li>télécharger ou distribuer sciemment des fichiers contenant des virus, des fichiers corrompus, ou tout autre logiciel ou programme similaire pouvant nuire au fonctionnement du Service ;<br>\
<li>modifier, désassembler, décompiler, faire de l'ingénierie inverse, adapter ou créer des œuvres dérivées du Service ;<br>\
<li>sonder, scanner, tester la vulnérabilité ou contourner les mécanismes de sécurité utilisés par les sites, serveurs ou réseaux connectés au Service ;<br>\
<li>prendre sciemment toute mesure qui impose une charge déraisonnable ou disproportionnée sur les sites, serveurs ou réseaux connectés au Service ;<br>\
<li>accéder ou utiliser, ou tenter de le faire, les données de tout autre abonné au Service que vous-même ;<br>\
<li>de réduire ou d'entraver malicieusement l'accessibilité du Service ; ou<br>\
<li>utiliser autrement le Service en violation de toute loi applicable.<br>\
</ol>\
Macadamian se réserve le droit d'enquêter sur toute violation des présentes conditions et de prendre les mesures appropriées dans toute la mesure permise par la loi.<br>\
<br>LIMITATION DE RESPONSABILITÉ ET EXCLUSION DE GARANTIE.<br>\
<br>\
Dans la mesure maximale permise par la loi, le Service mise en vente est offert tel quel. Vous comprenez et acceptez expressément cela : <br>\
<ol style=\"list-style-type:lower-alpha\"> \
<li>Votre utilisation du Service ainsi que l'achat et l'utilisation de tout service connexe sont à vos propres risques.<br>\
<li>Le Service est fourni \"tel quel\" et \"tel que disponible\". Dans la mesure maximale permise par la loi, Macadamian décline expressément toute garantie et condition de quelque nature que ce soit, qu'elle soit expresse ou implicite, y compris, mais sans s'y limiter, les garanties et conditions implicites de qualité marchande, d'exactitude, d'absence d'erreur, d'adéquation à un usage particulier et de non-contrefaçon.<br>\
<li>Sans limiter la généralité de ce qui précède, Macadamian ne garantit pas que (i) le service répondra à toutes vos exigences ; (ii) le service sera ininterrompu, opportun, sécurisé ou sans erreur ; ou (iii) toutes les erreurs dans le logiciel ou le service seront corrigées.<br>\
<li>Tout matériel téléchargé ou autrement obtenu par l'utilisation du Service ou toute utilisation non autorisée du Service est fait à votre propre discrétion et risque et vous êtes seul responsable de tout dommage à votre ordinateur ou autre dispositif ou perte de données résultant du téléchargement ou de l'utilisation d'un tel matériel.<br>\
<li>Aucun conseil ou information, qu'il soit oral ou écrit, obtenu par vous de Macadamian ou par l'intermédiaire ou à partir du Service ne créera de garantie non expressément mentionnée dans les présentes conditions de Service.<br>\
<li>Le contenu auquel on accède par l'intermédiaire du service est uniquement destiné à des fins d'information et ne remplace pas un avis médical professionnel, un diagnostic ou un traitement. Vous ne devez pas ignorer ou retarder l'obtention d'un avis médical professionnel en raison des informations qui vous ont été fournies par le Service.<br>\
<li>En aucun cas, Macadamian ou ceux dont elle est légalement responsable ne seront tenus responsables envers vous ou un tiers de dommages indirects, accessoires, spéciaux, punitifs ou consécutifs, quels qu'ils soient, y compris, mais sans s'y limiter, la perte de profits, d'activités ou de revenus, qu'elle soit contractuelle, délictuelle ou autre.  La responsabilité de Macadamian à votre égard concernant les dommages directs résultant de l'exécution ou de la non-exécution du service en vertu des présentes conditions, et pour toute réclamation, sera uniquement limitée aux dommages directs et ne dépassera en aucun cas un (1) mois de frais d'abonnement au service au total.<br>\
<li>Si une disposition des présentes Conditions est déclarée invalide, illégale ou inapplicable par un tribunal compétent, les dites conditions seront dissociées des présentes Conditions et toutes les autres conditions resteront pleinement en vigueur.<br>\
<li>Les présentes conditions seront régies et interprétées conformément aux lois de la province de l'Ontario, du Canada et de Macadamian, et vous acceptez irrévocablement de vous soumettre à la juridiction exclusive des tribunaux de la province de l'Ontario pour tout litige découlant des présentes conditions.<br>\
</ol>\
<br>INDEMNISATION<br>\
<br>\
En utilisant le Service, vous acceptez de défendre, d'indemniser et de dégager de toute responsabilité Macadamian, ses filiales et sociétés liées, ainsi que leurs dirigeants, agents, directeurs, employés, concédants et ayants droit respectifs, à l'égard de toutes les réclamations, causes d'action, dommages, obligations, pertes, responsabilités, coûts ou dettes et dépenses (y compris les honoraires et frais d'avocat) et de tous les montants payés en règlement découlant de ou liés à l'utilisation du Service, ou de votre violation des conditions ou des droits de tiers. Macadamian peut assumer la défense et le contrôle exclusifs de toute affaire pour laquelle vous avez accepté d'indemniser Macadamian et vous acceptez d'aider et de coopérer avec Macadamian dans la défense ou le règlement de ces affaires.<br>\
<br>CESSATION DES FONCTIONS<br>\
<br>\
Nous nous réservons le droit de mettre fin à votre abonnement à tout moment si vous commettez une violation matérielle ou persistante des présentes conditions à laquelle vous n'avez pas remédié (si elle peut être corrigée) dans les 14 jours suivant la réception d'une notification écrite vous demandant de le faire.  Vous avez également la possibilité de résilier votre abonnement à tout moment sans pénalité.<br>\
<br>\
En cas de résiliation de l'abonnement, vous perdrez toutes les données relatives à votre abonnement HealthQ et les utilisateurs cesseront immédiatement d'utiliser le service.<br> \
<br>\
Les dispositions des présentes conditions qui, de par leur nature, devraient survivre à la résiliation, y compris les dispositions relatives à la propriété intellectuelle, aux exclusions de garantie et aux limitations de responsabilité, à l'indemnisation . La résiliation de votre accès et de votre utilisation du service ne vous libère pas de toute obligation survenant ou s'accumulant avant la résiliation, ni ne limite la responsabilité que vous pourriez avoir envers Macadamian ou tout autre tiers.<br>\
<br>DROIT DE MODIFIER LE SERVICE<br>\
<br>\
Nous nous réservons le droit, à notre seule discrétion, de mettre en œuvre de nouveaux éléments dans le cadre et/ou à titre accessoire du Service, y compris les changements qui peuvent affecter le mode de fonctionnement précédent du Service. Nous espérons que de telles modifications amélioreront l'ensemble du Service, mais il est possible que vous ne soyez pas d'accord avec nous. Nous nous réservons également le droit de fixer des limites à la nature ou au nombre d'écrans quotidiens, à la possibilité de personnaliser le questionnaire, à l'accès au tableau de bord, et d'imposer d'autres limitations à tout moment, avec ou sans préavis. Par exemple, si vous utilisez le plan Bronze, vous ne bénéficierez pas de tous les avantages offerts aux souscripteurs du plan Gold.<br>\
<br>PROPRIÉTÉ INTELLECTUELLE<br>\
<br>\
Macadamian ne revendique aucun droit de propriété intellectuelle sur le contenu que vous fournissez au Service, étant entendu, toutefois, que Macadamian est libre d'utiliser, de tirer profit, de divulguer, de publier, de garder secret ou d'exploiter de toute autre manière tout commentaire, suggestion ou autre idée pour améliorer ou modifier de toute autre manière le Service (\"<b>Feedback</b>\"), sans compensation ou attribution à vous ou à toute personne à l'origine de ce Feedback. Vous reconnaissez que Macadamian détient tous les droits, titres et intérêts dans et sur le Service, y compris, sans limitation, tous les droits de propriété intellectuelle, et ces droits sont protégés par les lois canadiennes et internationales sur la propriété intellectuelle. Vous vous engagez à ne pas copier, reproduire, altérer, modifier ou créer des œuvres dérivées du Service. Sans limiter la généralité de ce qui précède :<br>\
<ol style=\"list-style-type:lower-alpha\"> \
<li>Toutes les marques (y compris les mots, expressions et logos, qu'ils soient enregistrés ou non) utilisées par Macadamian pour distinguer ou de manière à distinguer ses propres produits ou Services de ceux des autres, sont la propriété de Macadamian. Les marques commerciales de Macadamian ne peuvent être utilisées, reproduites ou imitées, en tout ou en partie, sans l'autorisation écrite préalable de Macadamian.<br>\
<li>Toutes les œuvres originales reproduites ou contenues dans le Service sont protégées par le droit d'auteur et sont la propriété de Macadamian. Vous reconnaissez que le fait pour toute personne de faire, sans le consentement du titulaire du droit d'auteur, tout ce que, selon les lois applicables en matière de droit d'auteur, seul le titulaire du droit d'auteur a le droit de faire, constitue une violation du droit d'auteur.<br>\
<li>Ce Service ou toute partie de celui-ci peut également être protégé par des dessins industriels ou des brevets. Macadamian se réserve tous les droits sur le Service qui ne sont pas expressément accordés.<br>\
</ol>\
<br>PAS DE RENONCIATION<br>\
<br>\
La négligence ou le retard de Macadamian à exercer un droit, un recours, une voie de recours, un pouvoir ou un privilège conformément aux conditions ne constitue pas une renonciation à ces droits, recours, pouvoirs ou privilèges. Pour être valable, une renonciation doit être faite par écrit et doit être signée par Macadamian. Une renonciation écrite à un manquement ne peut être interprétée comme constituant une renonciation à tout autre manquement ou défaut de même nature qui pourrait survenir à l'avenir.<br>\
<br>CONTACTEZ-NOUS<br>\
<br>\
Les utilisateurs ayant des questions concernant les présentes conditions ou la politique peuvent contacter Macadamian à l'adresse <a href=\"mailto:"+supportEmail+"\">" + supportEmail + "</a> ou au 179, promenade du Portage, 4e étage Gatineau, Canada, J8X 2K5<br>\
";