import { PrivacyPolicy_en as privacyPolicyText } from './PrivacyPolicy';
import { TermsOfService_en as termsOfServiceText } from './TermsOfService';

const APP_TITLE = 'RAAM Network Configuration';

export const en = {
  app: {
    title: APP_TITLE,
  },
  header: {
    title: APP_TITLE,
    login: 'Sign In',
    logout: 'Sign Out',
  },
  footer: {
    contactUs: 'Need help? Contact us.',
    contactEmail: 'mailto:support.raam@joinhealthq.com',
    privacyPolicyTitle: 'Privacy Policy',
    privacyPolicy: 'privacy policy',
    privacyPolicyText,
    termsOfServiceTitle: 'Terms of Service',
    termsOfService: 'terms of service',
    termsOfServiceText,
  },
  signin: {
    please_sign_in: 'Please sign in...',
    title: 'Sign In',
    message: 'Use your credentials to sign into the virtual waiting room.',
    email_label: 'Email Address:',
    email_placeholder: 'please enter your email address',
    password_label: 'Password:',
    password_placeholder: 'please enter password',
    remember_me: 'Remember me',
    button_label: 'Sign In',
    forgot_password: 'Forgot password?',
    oidc: {
      title: 'Welcome to the RAAM Network Configuration',
      select_method: 'Select your method to sign in',
      continue: 'Continue',
      signout: 'Sign out',
      bookmark_hint:
        'If you’re going to be coming back, be sure to bookmark this page',
    },
    error: {
      title: 'Cannot Sign In',
      message1:
        'There has been an error with your attempt to sign in, please try again, ensuring you are using the correct credentials.',
      message2:
        'If trouble persists, please contact your inQ RAAM Clinic administrator for assistance.',
      switch_user:
        'To sign in using different credentials, ensure you are signed out from the current account.',
      switch_user_link: 'Sign out here.',
    },
  },
  forgot_password: {
    title: 'Forgot Password',
    message: 'Enter your email to begin resetting your password.',
    email_label: 'Email Address:',
    email_placeholder: 'please enter your email address',
    button_label: {
      cancel: 'Cancel',
      submit: 'Submit',
    },
    success:
      'An email has been sent to {{email}} with instructions for resetting your password.',
  },
  change_password: {
    title: 'Change Password',
    message: 'Enter your New Password',
    password_label: 'Password',
    confirm_password_label: 'Confirm Password',
    button_label: {
      cancel: 'Cancel',
      change: 'Change Password',
    },
    success: 'You can now |sign in| with your new password.',
    success_sign_in: 'Sign In',
    error: {
      min: 'Password must contain a minimum of 8 characters',
      lowercase: 'Password must contain at least one lowercase character',
      uppercase: 'Password must contain at least one uppercase character',
      digits: 'Password must contain at least one number',
      spaces: 'Password must not contain spaces',
    },
  },
  not_implemented: 'State "{{connectionState}}" not implemented"',
  reports: {
    registrationMethodToday: 'Today',
    registrationMethodYesterday: 'Yesterday',
    registrationMethodThisMonth: 'This Month',
    registrationMethodThisWeek: 'This Week',
    form: {
      title: 'Network Reporting',
      downloadUsageReports: 'Download Usage Reports',
      description:
        'Select the appropriate type and duration for the data required for the report and download as a .csv file for each',
      isVisitReport: {
        label: 'Client visits',
        hint: 'This report will show the client visit details.',
      },
      isDenialReport: {
        label: 'Clients denied entry',
        hint: 'This report will show the clients that have been denied into the clinic.',
      },
      isQueueReport: {
        label: 'Queue times',
        hint: 'This report will show the client experience in the queue.',
      },
      isEventLog: {
        label: 'Event Log',
        hint: 'This report will show how the individual clinician interacted with the patient.',
      },
      aggregateResult: {
        label:
          'Compile reports so all selected clinics data is in one table for each report type.',
      },
      reportDuration: {
        label: 'Report Duration:',
        option1: 'Last week',
        option2: 'This month',
        option3: 'Last month',
        option4: 'Custom Date Range',
      },
      customDateRange: {
        title: 'Reporting Period',
        subheading:
          'Select the date range for the reports (day starts / ends at midnight)',
        startDate: 'Start Date',
        endDate: 'End Date',
        header: 'Custom Date Range',
        error: {
          noDateRange: 'No date range selected',
          invalidDate: 'Invalid Date',
          dateInFuture: 'Selected date in the future',
          rangeNotSane: 'End date before start date',
          rangeTooLong: 'Date range must be less than {{max}} days',
          noStartDate: 'No start date selected',
        },
      },
      networkClinic: {
        title: 'Network Clinics',
        subheading: 'Select the clinic(s) you would like reports for.',
      },
      selectClinic: {
        title: 'Network Clinics',
        subheading: 'Select the clinic(s) you would like reports for.',
        primary: 'Clinic',
        tooltip: {
          selectAll: 'Select all',
          clearAll: 'Clear all',
        },
      },
      warning:
        "Please be aware that even though this data has been scrubbed as best as possible, it could still contain some level of PHI and should only be downloaded and used as per your organization's data security procedures.",
      submit: {
        button: 'Download Selected Reports',
      },
    },
    main: {
      satisfactionScore0: 'No satisfaction score',
      satisfactionScore1: '1 - Very dissatisfied',
      satisfactionScore2: '2 - Somewhat dissatisfied',
      satisfactionScore3: '3 - Neither dissatisfied nor satisfied',
      satisfactionScore4: '4 - Somewhat satisfied',
      satisfactionScore5: '5 - Very satisfied',
    },
  },
  email_reports: {
    title: 'Monthly Reports',
    description:
      'These individuals will receive monthly reports emailed to them on the first of each month.',
    table: {
      aria: 'Email Report Recipient Table',
      name: 'Name',
      email: 'Email',
      network_usage_report: 'Network Usage Report',
      client_visits: 'Client Visits Report',
      denied_entry: 'Denied Entry Report',
      queue_times: 'Queue Times Report',
    },
  },
  recipient: {
    management: {
      add_title: 'Add Recipient',
      edit_title: 'Edit Recipient',
      add_description: 'Enter the details of the new recipient.',
      edit_description: 'Enter the details of the recipient.',
    },
    error: {
      add: 'Something went wrong adding the recipient. Please verify the recipient details. If the error persists, contact your administrator.',
      update:
        'Something went wrong updating the recipient. Please verify the recipient details. If the error persists, contact your administrator.',
      remove:
        'Something went wrong removing the recipient. If the error persists, contact your administrator.',
    },
  },
  user: {
    management: {
      title: 'User Management',
      description:
        'These are the admin users of the Access RAAM Network application.',
      table: {
        aria: 'Admin User Table',
        name: 'Name',
        affiliation: 'Affiliation',
        email: 'Email',
      },
      error: `Something went wrong adding or modifying the user.
        Please verify the user details.
        If the error persists, contact your administrator.`,
    },
    add_title: 'Add User',
    edit_title: 'Edit User',
    add_description: 'Enter the details of the new user.',
    edit_description: 'Enter the details for the user.',
    name: 'Name',
    clinic_name: 'Clinic Name',
    email: 'Email',
    cancel: 'Cancel',
    save: 'Save',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    confirm: {
      title: 'Delete User?',
      body: 'Are you sure you want to delete {{userName}}? This action cannot be undone.',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
  },
};
