import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useConnection } from 'hive-react-utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmailAddressValid from '../utils/email';
import { InQTextField } from './InQTextField';
import { SnackError } from './SnackError';

interface IRecipientData {
  recipientId: string;
  name: string;
  email: string;
  reportConfig: {
    usageReport: boolean;
  };
}

export const INIT_RECIPIENT_DATA: IRecipientData = {
  recipientId: '',
  name: '',
  email: '',
  reportConfig: {
    usageReport: true,
  },
};

export function RecipientDialog({
  open,
  handleClose,
  data,
  isEdit,
}: {
  open: boolean;
  handleClose: () => void;
  data: IRecipientData;
  isEdit: boolean;
}) {
  const { t } = useTranslation();
  const connection = useConnection();
  const [isAdding, setIsAdding] = useState(false);
  const [recipientData, setRecipientData] = useState(INIT_RECIPIENT_DATA);
  const [snackError, setSnackError] = useState(false);

  useEffect(() => {
    setIsAdding(false);
    if (!open) {
      setRecipientData(INIT_RECIPIENT_DATA);
      setSnackError(false);
    } else {
      setRecipientData({
        ...INIT_RECIPIENT_DATA,
        recipientId: (isEdit && data.recipientId) || '',
        name: (isEdit && data.name) || '',
        email: (isEdit && data.email) || '',
        reportConfig:
          (isEdit && data.reportConfig) || INIT_RECIPIENT_DATA.reportConfig,
      });
    }
  }, [data, isEdit, open]);

  const handleAdd = async () => {
    setIsAdding(true);
    try {
      if (!connection) {
        throw new Error('no connection');
      }
      await connection.bee.api.invoke(
        'recipient.createReportRecipient',
        recipientData.name,
        recipientData.email,
        INIT_RECIPIENT_DATA.reportConfig
      );
      handleClose();
    } catch (e) {
      console.error('error adding recipient: ', e);
      setSnackError(true);
    }
  };

  const handleUpdate = async () => {
    setIsAdding(true);
    try {
      if (!connection) {
        throw new Error('no connection');
      }
      await connection.bee.api.invoke(
        'recipient.updateReportRecipient',
        recipientData.recipientId,
        recipientData.name,
        recipientData.email,
        recipientData.reportConfig
      );
      handleClose();
    } catch (e) {
      console.error('error adding recipient: ', e);
      setSnackError(true);
    }
  };

  const handleSnackClose = () => {
    setIsAdding(false);
    setSnackError(false);
  };

  const isInvalid =
    !isEmailAddressValid(recipientData.email) || !recipientData.name;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {isEdit
          ? t('recipient.management.edit_title')
          : t('recipient.management.add_title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isEdit
            ? t('recipient.management.edit_description')
            : t('recipient.management.add_description')}
        </DialogContentText>
        <InQTextField
          required
          fullWidth
          id="name"
          label={t('user.name')}
          value={recipientData.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setRecipientData((prev) => {
              return { ...prev, name: event.target.value };
            });
          }}
        />
        <InQTextField
          required
          fullWidth
          id="clinicName"
          label={t('user.email')}
          value={recipientData.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setRecipientData((prev) => {
              return { ...prev, email: event.target.value };
            });
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          {t('user.cancel')}
        </Button>
        <Button
          disabled={isInvalid || isAdding}
          onClick={isEdit ? handleUpdate : handleAdd}
          color="primary"
          variant="contained"
        >
          {isEdit ? t('user.update') : t('user.save')}
        </Button>
      </DialogActions>
      <SnackError
        open={snackError}
        handleClose={handleSnackClose}
        message={t(isEdit ? 'recipient.error.update' : 'recipient.error.add')}
      />
    </Dialog>
  );
}
