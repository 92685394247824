import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import Footer from '../Footer';
import Header from '../Header';

export default function RouteElement({
  children,
}: PropsWithChildren<any>): JSX.Element {
  return (
    <Stack height="100%">
      <Header />
      <Stack flexGrow="1">{children}</Stack>
      <Footer />
    </Stack>
  );
}
