import { Instance } from 'hive-client-utils';
import { useConnection, useInstancesByClassName } from 'hive-react-utils';
import { PropsWithChildren, createContext, useEffect, useMemo } from 'react';

export interface IDataContext {
  connection?: any;
  dfd: IDFD[];
  currentUser?: IUser;
  users?: IUser[];
  emailRecipients?: IEmailRecipient[];
}

interface IDFD extends Instance {
  properties: {
    enabled: boolean;
    orgId: string;
    name: string;
    url: string;
    hours: object;
    breakHours: object;
    locationIds: Array<string>;
  };
}

export interface IUser extends Instance {
  properties: {
    userId: string;
    email: string;
    phone: string;
    displayName: string;
    clinicName: string;
  };
}

export interface IEmailRecipientProps {
  name: string;
  email: string;
  reportConfig: {
    usageReport: boolean;
  };
}

export interface IEmailRecipient extends Instance {
  properties: IEmailRecipientProps;
}

const DataContext = createContext<IDataContext>({
  dfd: [] as IDFD[],
});

export default DataContext;

export function DataContextProvider({
  children,
}: PropsWithChildren<any>): JSX.Element {
  const connection = useConnection();

  const username = useMemo(() => connection?.username, [connection]);

  const dfds = useInstancesByClassName<IDFD>('clinic', 'dfd');
  const dfd = useMemo(() => dfds || [], [dfds]);

  const users = useInstancesByClassName<IUser>('user', 'info');

  const currentUser = useMemo(
    () => users?.find((i) => i.properties.email === username),
    [users, username]
  );

  const emailRecipients = useInstancesByClassName<IEmailRecipient>(
    'recipient',
    'recipient'
  );

  const value = useMemo(
    () => ({
      connection,
      dfd,
      currentUser,
      users,
      emailRecipients,
    }),
    [connection, dfd, currentUser, users, emailRecipients]
  );

  // This is a keep alive of some sort. Whenever a user is deleted (or token revoked, etc), any calls to Hive or the
  // web socket should fail.
  // Since the app is reactive, it might not be making any calls.

  // Anything thing that should happen is that the web socket should die, then fail when trying to reconnect. The
  // Connection service should be getting the 'reactions.error` event and disconnect on 401/403, but that doesn't
  // seem to happen...

  // For the moment, we will be making a call to Hive every 30s, which will trigger the 'shell.error' event, which will
  // make the ConnectionService handle the disconnection. We will then end up on the login screen.
  useEffect(() => {
    if (!connection || !connection.bee) {
      return;
    }

    const interval = setInterval(() => {
      // We don't care about the result nor exception. Will be handled in ConnectionService.
      connection.bee.auth.hasRole('everyone').catch(() => {});
    }, 30000);

    return () => clearInterval(interval);
  }, [connection]);

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}
