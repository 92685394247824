import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useConnection } from 'hive-react-utils';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmailAddressValid from '../utils/email';
import { InQTextField } from './InQTextField';
import { SnackError } from './SnackError';

interface UserData {
  userId: string;
  email: string;
  phone: string;
  displayName: string;
  clinicName: string;
}

export const initUserData = {
  userId: '',
  email: '',
  phone: '',
  displayName: '',
  clinicName: '',
};

export function UserDialog({
  open,
  handleClose,
  data,
  isEdit,
}: {
  open: boolean;
  handleClose: () => void;
  data: UserData;
  isEdit: boolean;
}) {
  const { t } = useTranslation();
  const connection = useConnection();
  const [isAdding, setIsAdding] = useState(false);
  const [snackError, setSnackError] = useState(false);
  const [userData, setUserData] = useState<UserData>(initUserData);

  useEffect(() => {
    setIsAdding(false);
    if (!open) {
      setUserData(initUserData);
      setSnackError(false);
    } else {
      setUserData({
        userId: isEdit ? _.get(data, 'userId', '') : '',
        email: isEdit ? _.get(data, 'email', '') : '',
        phone: isEdit ? _.get(data, 'phone', '') : '',
        displayName: isEdit ? _.get(data, 'displayName', '') : '',
        clinicName: isEdit ? _.get(data, 'clinicName', '') : '',
      });
    }
  }, [data, isEdit, open]);

  const handleAdd = async () => {
    setIsAdding(true);
    try {
      if (!connection) {
        throw new Error('no connection');
      }
      await connection.bee.api.invoke(
        'network.createNetworkUser',
        userData.email,
        '',
        userData.displayName,
        userData.clinicName
      );
      handleClose();
    } catch (e) {
      console.error('error adding user: ', e);
      setSnackError(true);
    }
  };

  const handleUpdate = async () => {
    setIsAdding(true);
    try {
      if (!connection) {
        throw new Error('no connection');
      }
      await connection.bee.api.invoke(
        'network.updateNetworkUser',
        userData.userId,
        userData.email,
        userData.phone,
        userData.displayName,
        userData.clinicName
      );
      handleClose();
    } catch (e) {
      console.error('error updating user: ', e);
      setSnackError(true);
    }
  };

  const handleSnackClose = () => {
    setIsAdding(false);
    setSnackError(false);
  };

  const isInvalid =
    !isEmailAddressValid(userData.email) ||
    !userData.displayName ||
    !userData.clinicName;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {isEdit ? t('user.edit_title') : t('user.add_title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isEdit ? t('user.edit_description') : t('user.add_description')}
        </DialogContentText>
        <InQTextField
          required
          fullWidth
          id="name"
          label={t('user.name')}
          value={userData.displayName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUserData((prev) => {
              return { ...prev, displayName: event.target.value };
            });
          }}
        />
        <InQTextField
          required
          fullWidth
          id="clinicName"
          label={t('user.clinic_name')}
          value={userData.clinicName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUserData((prev) => {
              return { ...prev, clinicName: event.target.value };
            });
          }}
        />
        <InQTextField
          required
          fullWidth
          id="email"
          label={t('user.email')}
          value={userData.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUserData((prev) => {
              return { ...prev, email: event.target.value };
            });
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          {t('user.cancel')}
        </Button>
        <Button
          disabled={isInvalid || isAdding}
          onClick={isEdit ? handleUpdate : handleAdd}
          color="primary"
          variant="contained"
        >
          {isEdit ? t('user.update') : t('user.save')}
        </Button>
      </DialogActions>
      <SnackError
        open={snackError}
        handleClose={handleSnackClose}
        message={t('user.management.error')}
      />
    </Dialog>
  );
}
