import { ComponentsOverrides, Theme } from '@mui/material';
import { COLOURS } from '../constants/constants';

const cardStyleOverrides: ComponentsOverrides<Theme>['MuiCard'] = {
  root: {
    '.oidc &': {
      padding: '24px',
      border: `1px ${COLOURS.grey.mediumish} solid`,
      'border-radius': '8px',
      'box-shadow': 'unset',
    },
  },
};

export const MuiCard = {
  styleOverrides: cardStyleOverrides,
};

const contentStyleOverrides: ComponentsOverrides<Theme>['MuiCardContent'] = {
  root: {
    padding: 0,
    width: '132px',
    display: 'flex',
    'flex-direction': 'column',
    'align-items': 'center',
    'justify-content': 'space-evenly',

    '*:not(:first-child)': {
      'margin-top': '16px',
    },

    svg: {
      width: '96px',
      height: '96px',
    },

    '.MuiTypography-body1': {
      'margin-left': '-14px',
      'margin-right': '-14px',
      'font-size': '14px',
      'font-family': 'Roboto',
      'font-style': 'italic',
      'font-weight': '500',
      'word-wrap': 'break-word',
      'text-align': 'center',
    },

    '.MuiButton-contained': {
      'font-size': '18px',
      'font-weight': '400',
      'text-transform': 'none',
    },

    ':last-child': {
      'padding-bottom': 'unset',
    },
  },
};

export const MuiCardContent = {
  styleOverrides: contentStyleOverrides,
};
